// const defaultKey = 'globalchat.us'
const defaultKey = 'www.souldates.de'

const communityMeta = {
  "globalchat.us": {
    header_heading1: 'Global',
    header_heading2: 'Chat',
    main_heading:"Global Chat Dating",
    domain:"globalchat.us",
    mail:"support@globalchat.us",
    internet:"https://globalchat.us",
    address:"GWIK LLC, 5203 Juan Tabo Blvd. 4NE Suite 2b, Albuquerque, NM 87111 USA",
    support:"https://tawk.to/chat/60d8ca3565b7290ac6382e01/1f97dbp6v",
    addressline1:"GWIK LLC, 5203 Juan Tabo Blvd.",
    addressline2:"4NE Suite 2b, Albuquerque, NM 87111 USA",
    currency:'usd'
  },
  "www.souldates.de": {
    header_heading1: 'Soul',
    header_heading2: 'Dates',
    main_heading:"Soul Dates",
    domain:"Soul Dates",
    mail:"support@souldates.de",
    internet:"https://www.souldates.de",
    address:"s",
    support:"",
    addressline1:"",
    addressline2:"",
    currency:'usd'
  },
  "funnyflirts.net": {
    header_heading1: 'Flirttool',
    header_heading2: 'Chat',
    main_heading:"Flirttool",
    domain:"funnyflirts.net",
    mail:"support@globalchat.us",
    internet:"https://globalchat.us",
    address:"GWIK LLC, 5203 Juan Tabo Blvd. 4NE Suite 2b, Albuquerque, NM 87111 USA",
    support:"https://tawk.to/chat/60d8ca3565b7290ac6382e01/1f97dbp6v",
    addressline1:"GWIK LLC, 5203 Juan Tabo Blvd.",
    addressline2:"4NE Suite 2b, Albuquerque, NM 87111 USA",
    currency:'usd'
  },
  "funnyflirts.net": {
    header_heading1: 'Funny',
    header_heading2: 'Flirts',
    main_heading:"Funny Flirts",
    domain:"funnyflirts.net",
    mail:"",
    internet:"",
    address:"GWIK LLC, 5203 Juan Tabo Blvd. 4NE Suite 2b, Albuquerque, NM 87111 USA",
    support:"https://tawk.to/chat/60d8ca3565b7290ac6382e01/1f97dbp6v",
    addressline1:"GWIK LLC, 5203 Juan Tabo Blvd.",
    addressline2:"4NE Suite 2b, Albuquerque, NM 87111 USA",
    currency:'try'
  },
};

export const getCommunityStaticMeta = (domain) => {
  let meta = communityMeta[domain];
  if(!meta) return communityMeta[defaultKey]
  return meta
}
