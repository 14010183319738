import gql from 'graphql-tag'

export const USER_LOGIN = gql `
    mutation login($usernameOrEmail: String!, $password: String!, $addr: String!) {
        login(usernameOrEmail: $usernameOrEmail, password: $password, addr:$addr) {
            errors {
                field
                message
            }
            token
            user {
                authSource
                profilePic
                username
                email
                profileSetupStep
                profileComplete
                photos {
                    id
                    image
                    unsafe
                }
                base_profile {
                    id
                    firstName
                    lastName
                }
                user_basic{
                  location
                }
            }
            credit{
                id
                balance
                flirtons
                flirtonsAvailable
            }
        }
    }
`

export const LOGGEDIN_DATA = gql `
    query me($addr:String!){
        me(addr:$addr){
            likes
            visits
            isAff
            isIkm
            isSuper
            user{
                package_activity{
                  id
                  active
                  start_stamp
                  package{
                    id
                    timeValue
                    packageStart
                    packageEnd
                    cover
                    packageName
                    packageEndStamp
                    packageStartStamp
                  }
                }
                activePackage{
                  packageType
                }
                activePackageExpireTimestamp      
                has_hpass
                used_hpass
                start_timestamp
                h_pass_start
                finished_hpass
                publicIp  
                tempLocationDetail
                profilePic
                coverVideo
                username
                usrType
                email
                age
                authSource
                profileSetupStep
                profileComplete
                photos {
                    id
                    image
                    unsafe
                }
                base_profile {
                    id
                    firstName
                    lastName
                    phone
                }
                user_basic{
                    id
                    bio
                    relationship
                    education_level
                    workstatus
                    location
                }
                looks{
                    id
                    ethnicity
                    body_type
                    height
                    height_units
                    hair_color
                    preference
                }
                personality{
                    id
                    character
                    children
                    friends
                    pets
                }
                lifeStyle{
                    id
                    live_with
                    car
                    religion
                    smoke
                    drinking
                    travel
                }
                favourite{
                    id
                    music_genre
                    dish
                    song
                    hobby
                    city
                    sport
                    book
                    movie
                    color
                    tv_show
                }
                socialLink{
                  id
                  facebook
                  instagram	
                  twitter
                  linkedIn
                }
                createdAt
            }
            credits{
                balance
                flirtons
                flirtonsAvailable
            }
            notificationCount
        }
    }
`

export const LOGOUT = gql `
    mutation{
    logout
    }
`

export const USER_REGISTER = gql `
    mutation register($options:UsernamePasswordInput!,$addr: String!,$userType:String!,$preference:String!,$modelData:ModelRegisterFields){
        register(options:$options, addr:$addr,userType:$userType,preference:$preference,modelData:$modelData){
            errors{
                field
                message
            }
            user{
              profilePic
                username
                email
                usrType
                profileSetupStep
                profileComplete
                photos {
                    id
                    image
                    unsafe
                }
                base_profile {
                    id
                    firstName
                    lastName
                }
                user_basic{
                  location
                }
            }
            credit{
                id
                balance
                flirtons
                flirtonsAvailable
            }
            token
        }
    }
`
export const PUBLIC_PROFILE = gql `query userProfile($username:String!){
    publicProfile(username:$username){
      likes
      visits
      user{
          id
          username
          email
          profilePic
          profileSetupStep
          profileComplete
          package_activity{
                id
                active
                start_stamp
                package{
                  id
                  timeValue
                  packageStart
                  packageEnd
                  cover
                  packageName
                }
              }
          photos{
              id
              image
              unsafe
          }
          base_profile{
              id
              firstName
              lastName
          }
          createdAt
          looks{
            id
            ethnicity
            body_type
            gender
            height
            height_units
            hair_color
          }
          user_basic{
            id
            bio
            relationship
            workstatus
            education_level
          }
          lifeStyle{
            id
            live_with
            car
            religion
            smoke
            drinking
            travel
          }
      }
  }
}`

export const USER_PROFILE = gql `query userProfile($username:String!){
    userProfile(username:$username){
        likes
        visits
        user{
            id
            username
            email
            age
            profilePic
            profileSetupStep
            profileComplete
            package_activity{
                  id
                  active
                  start_stamp
                  package{
                    id
                    timeValue
                    packageStart
                    packageEnd
                    cover
                    packageName
                  }
                }
            photos{
                id
                image
                unsafe
            }
            base_profile{
                id
                firstName
                lastName
            }
            createdAt
            looks{
              id
              ethnicity
              body_type
              gender
              height
              hair_color
            }
            user_basic{
              id
              bio
              relationship
              workstatus
              education_level
            }
            lifeStyle{
              id
              live_with
              car
              religion
              smoke
              drinking
              travel
            }
        }
    }
  }`

export const UPDATE_PROFILE_PIC = gql `mutation updateProfilePic($file: Upload!) {
    updateProfilePic(file: $file)
  }
`

export const UPDATE_PROFILE_COVER_VIDEO = gql`mutation updateProfileCoverVideo($file: Upload!){
  updateProfileCoverVideo(file:$file)
}`
export const BASIC_SELCETIONS = gql `query{
    basicInfoSelections{
      hairColorTypes
      genders
      preferences
    }
}`

export const ADD_BASIC_INFO = gql `mutation addBasicInformation($options:BasicInfoInput!){
    addBasicInformation(options:$options)
}`

export const VERIFY_OTP = gql `mutation verifyOtp($otp:String!){
    verifyOtp(otp:$otp){
        user{            
            profilePic
            username
            email
            age
            usrType
            profileSetupStep
            profileComplete
            photos {
                id
                image
                unsafe
            }
            base_profile {
                id
                firstName
                lastName
            }
            user_basic{
              location
            }
        }
        credit{
            id
            balance
            flirtons
            flirtonsAvailable
        }
    }
}`

export const PROFILE_PIC_UPDATE = gql `mutation updateProfilePic($file: Upload!) {
    updateProfilePic(file: $file)
  }
`

export const MULTIPLE_UPLOAD = gql `mutation addProfilePics($files: [Upload!]!) {
    addProfilePics(files: $files){
        id 
        image
        unsafe
    }
  }
`
export const DEFAULT_SETTINGS = gql `query{
    userSettingsData{
      user{
        id
        email
        username
        base_profile{
          id
          firstName
          lastName
          phone
        }
        user_basic{
          id
          bio
          relationship
          workstatus
          education_level
          location
        }
        looks{
            id
            ethnicity
            body_type
            height
            hair_color
        }
        personality{
            id
            character
            children
            friends
            pets
        }
      }
    }
  }`

export const DM_TARGET_INFO = gql `query dmTargetInfo($dtarget:String!){
    dmTargetInfo(dtarget:$dtarget){
        target{
          id
          username
          usrType
          email
          base_profile{
              id
              firstName
              lastName
          }
          profilePic
        }
        convExsists
    }
  }`

export const UPDATE_BASE_PROFILE = gql `mutation updateBaseProfile($input:BasicUserDataUpdateInput!){
    updateBaseProfile(input:$input){
      user{
        base_profile{
            id
            firstName
            lastName
        }          
      }
    }
  }`

export const UPDATE_PROFILE_DETAILS = gql `mutation updateUserProfileDetails($input:ProfileSetupUpdate!){
    updateUserProfileDetails(input:$input){
      user{
        base_profile{
            id
            firstName
            lastName
        }
      }
    }
  }`

export const PROFILE_DETAIL_SETUP_TYPES = gql `query {
    profileDetailSetupTypes{
      workStatus
      relationShipStatus
      educationStatus
    }
  }`

export const LOOKS_PERSONALITY_TYPES = gql `query {
    looksAndPersonalitySetupTypes{
      ethnicity
      bodyType
      hairColor
      character
      children
      friends
      pets
    }
  }`

export const UPDATE_USER_LOOKS = gql `mutation updateUserLooksDetail($input:LooksAndPersonalityInputType!){ 
    updateUserLooksDetail(input:$input){
      user{
        base_profile{
          id
          firstName
          lastName
        }
      }
    }
  }`


export const UPDATE_USER_LISFESTYLE = gql `mutation updateUserLifeStyleDetail($input:LifeStyleAndFavouritesInputType!){
    updateUserLifeStyleDetail(input:$input){
      user{
        base_profile{
          id
          firstName
          lastName
        }
      }
    }
  }`

export const LIFE_STYLE_FAVOURITES_LS = gql `{
    lifeStyleAndFavouritesSetupTypes{
      livingWith
      car
      religion
      smoke
      drink
      travel
    }
  }`

export const CHANGE_PASSWORD = gql `mutation changeUserPassword($input:PasswordChangeInput!){
    changeUserPassword(input:$input)
}`

export const UPDATE_SOCIAL_LINK = gql `mutation updateSocialLinks($input:SocialLinkInput!){
  updateSocialLinks(input:$input){
    user{
      base_profile{
        id
        firstName
        lastName
      }
    }
  }
}`

export const ADD_CONTACT_MESSAGE = gql `mutation addContactMessage($input:ContactMessageInput!){
  addContactMessage(input:$input) 
}`

export const SEND_POKE = gql `mutation sendPoke($input:String!){
  sendPoke(input:$input){
    error
    poke{
      id
      createdAt
      updatedAt
    }
  }
}`


export const UDPATE_PASS_TIME = gql `mutation{
  updatePassTime
}`

export const START_PASS = gql `mutation{
  startHourlyPackage {
    package_activity{
      id
      active
      start_stamp
      package{
        id
        timeValue
        packageStart
        packageEnd
        cover
        packageName
        packageEndStamp
        packageStartStamp
      }
    }
    activePackage{
      packageType
    }
    activePackageExpireTimestamp
    has_hpass
    used_hpass
    start_timestamp
    h_pass_start
    finished_hpass
    publicIp  
    tempLocationDetail
    profilePic
    coverVideo
    username
    email
    authSource
    profileSetupStep
    profileComplete
    photos {
        id
        image
        unsafe
    }
    base_profile {
        id
        firstName
        lastName
        phone
    }
    user_basic{
        id
        bio
        relationship
        education_level
        workstatus
        location
    }
    looks{
        id
        ethnicity
        body_type
        height
        height_units
        hair_color
    }
    personality{
        id
        character
        children
        friends
        pets
    }
    lifeStyle{
        id
        live_with
        car
        religion
        smoke
        drinking
        travel
    }
    favourite{
        id
        music_genre
        dish
        song
        hobby
        city
        sport
        book
        movie
        color
        tv_show
    }
    socialLink{
      id
      facebook
      instagram	
      twitter
      linkedIn
    }
    createdAt
  }
}`

export const RAND_USERS = gql `query {
  randUserSet{
    counts
    users{
      id
      looks{
        gender
        preference
      }
      profilePic
    }
  }
}`

export const SOCIAL_AUTH_GOOGLE = gql `mutation socialMediaRegisterGoogle($input:String!, $addr: String!,$userType: String!,$preference: String!){
  socialMediaRegisterGoogle(
    input: $input, addr: $addr,userType:$userType,preference:$preference
  ) {
    errors {
        field
        message
    }
    token
    user {
        authSource
        profilePic
        username
        email
        profileSetupStep
        profileComplete
        photos {
            id
            image
            unsafe
        }
        base_profile {
            id
            firstName
            lastName
        }
        user_basic{
          location
        }
    }
    credit{
        id
        balance
        flirtons
        flirtonsAvailable
      }
  }
}`

export const SOCIAL_AUTH_FB = gql `mutation socialMediaRegisterGoogle($input:String!){
  socialMediaRegisterFb(
    input: $input
  ) {
    errors {
        field
        message
    }
    token
    user {
        authSource
        profilePic
        username
        email
        profileSetupStep
        profileComplete
        photos {
            id
            image
            unsafe
        }
        base_profile {
            id
            firstName
            lastName
        }
        user_basic {
          location
        }
    }
    credit{
        id
        balance
        flirtons
        flirtonsAvailable
      }
  }
}`

export const REMOVE_ACCOUNT = gql `mutation{
  removeUserAccount
}`

export const REPORT_USER = gql `mutation reportAccount($input:ReportInputType!){
  reportAccount(input:$input)
}`

export const BLOCK_USER = gql `mutation blockAccount($input:String!){
  blockAccount(input:$input,)
}`

export const GET_INTERNAL_PROMO = gql`query{
  internalPromoCollection(limit:3){
    items{
      title
      active
      event
      buttonLink
      banner{
        url
      }
    }
  }
}`

export const RESET_PASS = gql`mutation  forgotPassword($email:String!){
  forgotPassword(email:$email)
}`

export const CHK_TOKEN = gql`mutation  checkToken($token:String!){
  checkToken(token:$token)
}`

export const CHANGE_PASS = gql`mutation  changePassword($input:ChangePasswordInput!){
  changePassword(input:$input)
}`

export const GET_EVENTS = gql`query{
  flirttoolEvents{
    id
    packageId
    name
    cover
    detail
    access_link
    eventDate
    createdAt 
    price  
  }
}`

export const GET_EVENT = gql`query flirttoolEvent($event:String!){
  flirttoolEvent(event:$event){
    id
    access_link
    packageId
    name
    cover
    detail
    eventDate
    createdAt 
    price      
  }
}`

export const SEND_EVENT_ACCESS = gql`mutation  sendEventAccess($event:String!){
  sendEventAccess(event:$event)
}`

export const UPDATE_USER_LOCATION = gql`mutation  updateUserLocation($input:updateLocationInput!){
  updateUserLocation(input:$input)
}`