<template>
  <div class="tab-pane fade show active" id="tabs-Latest-Pics" role="tabpanel" aria-labelledby="tabs-home-tab">
    <div class=" mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-0 lg:px-0 lg:py-1">
      <div class="grid  lg:grid-cols-4 grid-cols-1 sm:max-w-sm sm:mx-auto lg:max-w-full">
        <div class="overflow-hidden transition-shadow duration-300 hidden lg:block   bg-transparent rounded">
          <a href="/"><img :src="getFeaturedPicture(0)" class="object-cover w-16 h-36 lg:h-96 lg:w-full rounded"
              alt="" /></a>

        </div>
        <div class="overflow-hidden transition-shadow duration-300 hidden lg:block  bg-transparent rounded">
          <a href="/"><img :src="getFeaturedPicture(1)" class="object-cover w-16 h-36 lg:h-96 lg:w-full  rounded"
              alt="" /></a>

        </div>
        <!-- <div class="overflow-hidden transition-shadow duration-300  bg-transparent rounded">
          <router-link :to="'/model-gallery/@' + $route.params.username"><img src="@/assets/ftmodels/place/4.jpg"
              class="object-cover w-16 h-36 lg:h-96 lg:w-full  rounded" alt="" /></router-link>
        </div> -->

        <!-- private gallery -->
        <div class="overflow-hidden transition-shadow duration-300 bg-transparent rounded" style="height: 385px;">
          <div
            class="flex flex-col justify-between p-8 transition-shadow duration-300 bg-pink-500 border-0 rounded shadow-sm sm:items-center hover:shadow">
            <div class="text-center">
              <div class="text-lg font-semibold">Visit My </div>
              <div class="flex items-center justify-center mt-2">
                <div class="mr-1 text-xl font-bold">Private Gallery</div>
              </div>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-full h-24">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                </svg>
              </div>
              <div class="mt-2 space-y-3">
                <div class="text-gray-50"> {{pictures}} Photos</div>
                <div class="text-gray-50">{{videos}} Vidoes</div>
                <div class="text-gray-50">{{ picsPercent }}% pictures</div>
              </div>
            </div>
            <div>
              <a target="_blank" href="https://porntool.live"
                class="inline-flex items-center justify-center btn-sm w-full h-12 px-6 mt-6 font-medium tracking-wide text-white transition duration-200 bg-pink-800 rounded shadow-md hover:bg-pink-500 focus:shadow-outline focus:outline-none">
                Open
              </a>

            </div>
          </div>

        </div>

         <!-- private gallery -->
        <div class="overflow-hidden transition-shadow duration-300 hidden lg:block  bg-transparent rounded">
          <a href="/"><img :src="getFeaturedPicture(2)" class="object-cover w-16 h-36 lg:h-96 lg:w-full rounded"
              alt="" /></a>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MODEL_MEDIA_COUNT } from "@/queries/matches";
export default {
  data() {
    return {
      pictures:0,
      videos:0,
      picsPercent:0
    }
  },
  apollo: {
    userProfile: {
      query: MODEL_MEDIA_COUNT,
      variables() {
        return {
          username: this.$route.params.username,
        };
      },
      result({ data: { getModelMediaCount } }) {
          this.pictures = getModelMediaCount.pictures
          this.videos = getModelMediaCount.videos
          this.picsPercent = Math.ceil((this.pictures / (this.pictures + this.videos)) * 100)
          if(this.pictures == 0) this.picsPercent = 0
      },
    },
  },
  name: "Tab1",
  props:['photos'],
  methods: {
    getFeaturedPicture(index) {
      console.log(this.photos,"this.photosthis.photos")
      if(this.photos.length < index +1) {
        return 'https://funnyflirts.net/media/s6DzcWBHkW-Female.jpg'
      }else {
        return `https://funnyflirts.net/media/${this.photos[index].image}`
      }
    }
  },
};
</script>