
<template>
  <div>
    <popup :showing="openPop" @close="openPop = false">
      <div class="relative h-0 overflow-hidden max-w-full w-full" style="padding-bottom: 56.25%">
        <iframe src="https://www.youtube.com/embed/VI4iURFwJ3Y" frameborder="0" allowfullscreen
          class="absolute top-0 left-0 w-full h-full"></iframe>
      </div>
    </popup>

    <!--hero banner-->
    <div class="flex overflow-hidden hidden lg:block xl:block 2xl:block bg-white pt-0">
      <div class="bg-gray-900 opacity-100 hidden fixed inset-0 z-10" id="sidebarBackdrop"></div>
      <!--NAV-->
      <div>

      </div>
      <!--NAV-->
      <DIV class="flex justify-center bg-red-700">
        <P class="py-3 font-bold uppercase text-white"> {{staticMeta.header_heading1}}<span class="text-white-900"> {{staticMeta.header_heading2}}</span> </P>
      </DIV>
      <!-- nav starts here -->
      <div class="px-4 py-3 mx-auto bg-white sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
        <div class="relative flex items-center justify-between">
          <a href="/" aria-label="Company" title="Company" class="inline-flex items-center">

            <img :src="getCommunityMeta.logo" class="h-16" alt="">
          </a>
         
          <ul class="flex items-center hidden space-x-8 lg:flex">
            <!-- <li><a href="/" aria-label="Our product" title="Our product"
                class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400">Product</a>
            </li>
            <li><a href="/" aria-label="Our product" title="Our product"
                class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400">Features</a>
            </li>
            <li><a href="/" aria-label="Product pricing" title="Product pricing"
                class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400">Pricing</a>
            </li>
            <li><a href="/" aria-label="About us" title="About us"
                class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400">About
                us</a></li>-->
            <li> 
              <router-link class="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide bg-red-700 text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none" to="/login">
                {{ $t('SignIn')}}
              </router-link>
            </li>
              <li> 
              <router-link class="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide bg-red-700 text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none" to="/register">
                {{ $t('Register')}}
              </router-link>
            </li>
            <li>
               <div class="p-6 space-y-6">
                  <select @change="changeLang" v-model="$store.state.core.lang" id="countries" class="bg-gray-50 border  border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    <option :value="null">No language selected</option>
                    <option value="en">English</option>
                    <option value="tr">Turkish</option>
                    <option value="fr">French</option>
                    <option value="de">German</option>
                    <option value="polish">Polish</option>
                      <option value="Spanish">Spanish</option>
                      <option value="italic">Italia</option>
                      <option value="português">Português</option>
                      <option value="sv">Schwedisch</option>
                    <option value="da">Danish</option>
                    <option value="fi">Finnish</option>
                    <option value="sr">Serbian</option>
                    <option value="ru">Russisch</option>
                    <option value="ja">Japanisch</option>
                  <option value="ko">Koreanisch</option>
                  <option value="fa">Iran</option>
                  <option value="arz">Arabic</option>
                  <option value="Kannada">Kannada</option>
                  <option value="Thai">Thai</option>
                  <option value="Amharisch">Amharisch</option>
                  <option value="	Ungarisch">	Ungarisch</option>
                  </select>
                <!-- <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    The European Union’s General Data Protection Regulation (G.D.P.R.) goes into effect on May 25 and is meant to ensure a common set of data rights in the European Union. It requires organizations to notify users as soon as possible of high-risk data breaches that could personally affect them.
                </p> -->
            </div>
            </li>
          </ul>
          <!-- Mobile menu -->
          <div class="lg:hidden">
            <button aria-label="Open Menu" title="Open Menu"
              class="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-deep-purple-50 focus:bg-deep-purple-50">
              <svg class="w-5 text-gray-600" viewBox="0 0 24 24">
                <path fill="currentColor" d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z">
                </path>
                <path fill="currentColor" d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"></path>
                <path fill="currentColor" d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z">
                </path>
              </svg>
            </button>
           
  
          </div>
        </div>
      </div>
      <!-- nav ends here -->
      <div id="main-content" class="h-full w-full bg-gray-50 relative overflow-scroll">
        <main>

          <div class="pt-0 px-0">
            <!--here-->
            <section class="relative mt-0 bg-white dark:bg-red-200 md:h-screen ">
              <div class="mb-0">
                <div>
                  <div class="relative" style="object-fit: cover ; width: 100%; height: 695px;">
                    <!-- <img :src="require('@/assets/perfect-morning-love-man-with-beard-pointing-lady4.jpg')"
                          class="absolute inset-0 justify-center object-cover w-full h-full sm:" alt="" /> -->
                    <imageslider class="absolute inset-0 justify-center object-cover w-full h-full " />

                    <!--Navigation -->


                    <div class="relative bg-gradient-to-r from-gray-900 bg-opacity-80 h-full  " style="height: 690; ">
                      <!--test start here--><!--test ends here-->
                      <template>
                        <div
                          class="px-4 py-1 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-">
                          <div class="flex flex-col justify-between lg:flex-row   mt-48">
                            <div class="mb-5 lg:max-w-lg lg:pr-5 lg:mb-0">
                              <div class="max-w-xl mb-16 justify-center">
                                <p
                                  class="max-w-lg text-left italic mb-6 font-sans text-6xl font-bold tracking-tight text-gray-50 sm:text-5xl lg:text-7xl   sm:leading-none">
                                  <!-- {{$t("SomeRandomHeadingGoesHere")}} -->
                                  {{staticMeta.main_heading}}

                                </p>
 <!-- <p class="text-white text-left font-sans italic">Lorem ipsum dolor sit amet consectetur adipisicing elit. Id ut vel facilis iste, dicta est minus alias, aliquam, velit nisi quo assumenda porro dignissimos doloremque temporibus eum saepe aspernatur ab.
</p> -->
                                <a target="_blank"
                                  href="https://play.google.com/store/apps/details?id=com.flirttooldating.app"
                                  class="hover:text-white w-full sm:w-auto flex bg-gray-800 hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 text-white rounded-lg items-center justify-center px-4 py-2.5 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700"
                                  style="width: 300px;">
                                  <svg class="mr-3 w-7 h-7" aria-hidden="true" focusable="false" data-prefix="fab"
                                    data-icon="google-play" role="img" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512">
                                    <path fill="currentColor"
                                      d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z">
                                    </path>
                                  </svg>
                                  <div class="text-left">
                                    <div class="mb-1 text-xs">Get in on</div>
                                    <div class="-mt-1 font-sans text-sm font-semibold">
                                      Google Play
                                    </div>
                                  </div>
                                </a>
                              </div>
                              
                              <div class="justify-start items-center  sm:flex sm:space-y-0  mb-5">

                                <router-link to="/register"><button
                                    class="btn rounded-lg hover:bg-red-400 hover:text-white px-10 font-bold font-sans bg-red-500 text-gray-50 shadow-2xl border-0 ">
                                    {{$t("ChatNow")}}
                                  </button></router-link>

                              </div>
                              <!-- <p class="text-gray-50 font-sans font-bold mb-6 text-xl text-center">
                                or get the app
                              </p> -->
                              <div class="justify-center items-center space-y-4 sm:flex sm:space-y-0 sm:space-x-4 mb-36">

                              </div>
                              <div class="relative overflow-hidden">

                                <div
                                  class="px-5 pt-6 mt-6 pb-5 text-center opacity-0 border border-gray-300 rounded w-11/12 bg-white lg:w-11/12">
                                 
                                </div>
                              </div>
                              <hr class="mb-6 border-gray-300" />
                            </div>
                            <div class="w-full max-w-xl xl:px-1 xl:w-5/12 inset-y-0 right-0 mt-24  lg:block hidden ">
                              <div class="flex lg:justify-end md:justify-center sm:justify-center     object-right ">

                                <!--box-->
                                <div class="card w-72 h-28 bg-white shadow-xl opacity-90 ">
                                  <div class="card-body -mt-10 ">
                                    <p class="font-sans">
                                      <span class="text-lg font-sans font-bold">{{$t('BasicPackage')}}</span>
                                      <br /><span class="text-xs mb-5"> {{$t('twohoursfreechatexperience')}}</span><router-link
                                        to="/login"><button
                                          class="btn btn-primary btn-sm  bg-red-700 w-36  hover:bg-red-300 shadow-lg font-sans border-0 hover:pink">
                                          {{$t('Tryitforfree')}}
                                        </button></router-link>
                                    </p>
                                    <div class="card-actions justify-center ">

                                    </div>
                                  </div>
                                </div>
                                <br />
                            </div>
                              <div class="flex lg:justify-end md:w-full md:justify-center sm:justify-center mt-1  ">
                                <!--box-->
                                <div class="card  w-72 h-28 bg-white shadow-xl opacity-90 ">
                                  <div class="card-body -mt-10">
                                    <p class="font-sans">
                                      <span class="text-lg font-sans font-bold ">{{$t('Gblobalfor1Day')}}
                                      </span>
                                      <br /><span class="text-xs mb-5"> {{$t('RealChatFlatrate')}}</span>
                                    </p><router-link to="/login"><button
                                        class="btn btn-primary btn-sm border-0 animate-pluse delay-75 hover:bg-red-300 hover:border-white hover:text-white  bg-red-700 border-gray-500 font-sans w-36 shadow-lg text-pink-50">
                                        {{$t('Grabit')}}
                                      </button></router-link>
                                    <div class="card-actions justify-center">

                                    </div>
                                  </div>
                                </div>
                                <br />
                              </div>
                            </div>
                          </div>

                        </div>
                      </template>
                      <div class="lg:block hidden">
                        <div
                          class="flex  lg:justify-end md:w-full lg:visible md:invisible sm:invisible md:justify-end sm:justify-end -mt-96  bottom-0 right-0 ">

                          <!-- <img
                              src="@/assets/modlogin/label.png" class="bottom-0 left-0 h-36  lg:h-72 sm:h-36 md:h-36 " alt=""> -->

                        </div>
                      </div>



                    </div>
                  </div>
                  <!-- ads here -->
                  <Adsense data-ad-client="ca-pub-2781511921274321" data-ad-slot="4412224045">
                  </Adsense>
                  <!-- ads here -->
                  <div class="bg-red-50 relative mt- " style="object-fit: cover ; width: 100%; height: 290px;">
                    <div
                      class="px-4 py-8 mx-auto bg-opacity-0   sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-10 lg:px-5 lg:py-5">
                      <img src="@/assets/purplethemefiles/1.png" alt=""
                        class="absolute inset-0 z-0 justify-center object-cover w-full h-full">
                      <p
                        class="text-2xl  my-section transform font-bold  translate-x-4 lg:text-4xl italic md:text-4xl sm:text-3xl font-sans text-gray-900">
                        {{staticMeta.header_heading1}} {{staticMeta.header_heading2}} <span class="text-red-500">  </span>
                      <p class=" my-section transform font-sans  translate-x-4">'{{staticMeta.domain}} {{$t('secondtext')}}'</p>
                    
                      <button class="my-section transform font-sans bg-red-500 font-sans border-0 shadow-2xl text-white  translate-x-4 btn
                      "> {{$t('Tryitforfree')}}</button>

                      <div class="max-w-xl sm:mx-auto lg:max-w-2xl">
                        <div class="flex mb-8 sm:text-center sm:mb-0">
                          <div class="max-w-xl mb-1 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                            <h2
                              class="max-w-lg mb-1 mt-1 w-full font-sans text-4xl italic leading-none tracking-tight text-white sm:text-4xl">
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class=" bg-red-700">
                    <div
                      class="hidden md:block px-4 bg-red-700 py-16 flex-flex-wrap mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                      <div class="grid grid-cols-2 ">
                        <div
                          class="grid gap-10 bg-white px-10 py-10 mx-auto sm:grid-cols-2 lg:grid-cols-2 lg:max-w-screen-lg">
                          <div>
                            <div class="relative pb-16 mb-4 rounded-full avatar w-36  h-36 shadow ">
                              <img class="absolute object-cover w-full h-full rounded-nonel"
                                src="https://funnyflirts.net/media/upload/f_pics/52587307_1.jpg" alt="Person" />
                            </div>
                            <div class="flex flex-col sm:text-center -mt-7">
                              <p class="text-lg font-semi-bold font-sans"> GabbyBaby316 </p>
                              <p class="mb-5 -mt-5 text-xs text-gray-800 font-sans">300+ Matching Profiles</p>

                            </div>
                          </div>
                          <div>
                            <div class="relative pb-36 mb-4 rounded-full shadow ">
                              <img class="absolute object-cover w-36  h-36 rounded-nonel "
                                src="https://funnyflirts.net/media/upload/f_pics/52810947_1.jpg" alt="Person" />
                            </div>
                            <div class="flex flex-col sm:text-center  -mt-7">

                              <p class="text-lg font-semi-bold font-sans">SabrinaH24</p>
                              <p class="mb-5 -mt-5 text-xs text-gray-800 font-sans">168+ Matching Profiles</p>

                            </div>
                          </div>
                          <div>
                            <div class="relative pb-36 mb-4  shadow ">
                              <img class="absolute object-cover w-36  h-36 rounded-nonel items-center"
                                src="https://funnyflirts.net/media/upload/trans_pics/9830777_0.jpg" alt="Person" />
                            </div>
                            <div class="flex flex-col sm:text-center  -mt-7">
                              <p class="text-lg font-semi-bold font-sans">DungonDad</p>
                              <p class="mb-5 -mt-5 text-xs text-gray-800 font-sans">101+ Matching Profiles</p>

                            </div>
                          </div>
                          <div>
                            <div class="relative pb-36 mb-4  shadow ">
                              <img class="absolute object-cover w-36  h-36 rounded-nonel"
                                src="https://funnyflirts.net/media/upload/f_pics/52779689_1.jpg" />
                            </div>
                            <div class="flex flex-col sm:text-center  -mt-7">
                              <p class="text-lg font-semi-bold font-sans">Jessikacay99</p>
                              <p class="mb-5 -mt-5 text-xs text-gray-800 font-sans">164+ Matching Profiles</p>

                            </div>
                          </div>
                        </div>

                        <div
                          class="grid gap-10 bg-white px-10 py-10 mx-auto sm:grid-cols-2 lg:grid-cols-2 lg:max-w-screen-lg">
                          <div>
                            <div class="relative pb-16 mb-4 rounded-full avatar w-36  h-36 shadow ">
                              <img class="absolute object-cover w-full h-full rounded-nonel"
                                src="https://funnyflirts.net/media/upload/f_pics/52587307_1.jpg" alt="Person" />
                            </div>
                            <div class="flex flex-col sm:text-center -mt-7">
                              <p class="text-lg font-semi-bold font-sans"> GabbyBaby316 </p>
                              <p class="mb-5 -mt-5 text-xs text-gray-800 font-sans">300+ Matching Profiles</p>

                            </div>
                          </div>
                          <div>
                            <div class="relative pb-36 mb-4 rounded-full shadow ">
                              <img class="absolute object-cover w-36  h-36 rounded-nonel "
                                src="https://funnyflirts.net/media/upload/f_pics/52810947_1.jpg" alt="Person" />
                            </div>
                            <div class="flex flex-col sm:text-center  -mt-7">

                              <p class="text-lg font-semi-bold font-sans">SabrinaH24</p>
                              <p class="mb-5 -mt-5 text-xs text-gray-800 font-sans">168+ Matching Profiles</p>

                            </div>
                          </div>
                          <div>
                            <div class="relative pb-36 mb-4  shadow ">
                              <img class="absolute object-cover w-36  h-36 rounded-nonel items-center"
                                src="https://funnyflirts.net/media/upload/trans_pics/9830777_0.jpg" alt="Person" />
                            </div>
                            <div class="flex flex-col sm:text-center  -mt-7">
                              <p class="text-lg font-semi-bold font-sans">DungonDad</p>
                              <p class="mb-5 -mt-5 text-xs text-gray-800 font-sans">101+ Matching Profiles</p>

                            </div>
                          </div>
                          <div>
                            <div class="relative pb-36 mb-4  shadow ">
                              <img class="absolute object-cover w-36  h-36 rounded-nonel"
                                src="https://funnyflirts.net/media/upload/f_pics/52779689_1.jpg" />
                            </div>
                            <div class="flex flex-col sm:text-center  -mt-7">
                              <p class="text-lg font-semi-bold font-sans">Jessikacay99</p>
                              <p class="mb-5 -mt-5 text-xs text-gray-800 font-sans">164+ Matching Profiles</p>

                            </div>
                          </div>
                        </div>

                      </div>


                    </div>
                  </div>




                </div>
              </div>

              <!--new footer-->
              <!-- promotion banner -->




              <!-- end of promotion banner -->




              <div  class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
              
                      <div class="grid gap-12 row-gap-8 lg:grid-cols-2">
                  <div class="flex flex-col justify-left"> 
                    <div class="max-w-xl mb-6 bg-opacity-40"> 
                      <h2
                        class="max-w-lg mb-6 font-sans italic text-left text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                        <!-- {{$t("SomeRandomHeadingGoesHere")}} --> {{staticMeta.main_heading}}

                      </h2>
                      <p class="text-base text-left text-gray-700 md:text-lg">
                        <!-- {{$t("SomeRandomHeadingGoesHere")}}  
                         Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
                        totam rem aperiam, eaque ipsa quae. explicabo. -->
                      </p>
                    </div>
                    <div class="grid gap-8 row-gap-8 sm:grid-cols-2">

                      <button class="btn  bg-red-500 text-white shadow-2xl font-sans">{{$t('Tryitforfree')}}</button>
                    </div>
                  </div>
                  <div class=" flex justify-center bg-gray-50">
                    <img class="object-cover w-96 h-56 rounded-full shadow-lg sm:h-96"
                      src="https://images.pexels.com/photos/927022/pexels-photo-927022.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=750&amp;w=1260"
                      alt="" />
                  </div>
                </div>
              </div>
              <div class="bg-red-700">
                <div
                  class="px-4 pt-16 mx-auto text-left sm:max-w-xl md:max-w-full lg:max-w-screen-xl bg-red-700 md:px-24 lg:px-8">
                  <div class="grid gap-10 row-gap-6 mb-8 sm:grid-cols-3 lg:grid-cols-4">
                    <div class="sm:col-span-2">
                      <div class="text-sm">
                        <p class="font-bold font-sans text-xl tracking-wide uppercase text-gray-50">
                          {{$t("About")}}
                        </p>
                        <div class="flex">
                          <!-- <p class="mr-1 text-pink-100 font-sans text-sm">Flirttool</p> -->
                        </div>
                        <div class="flex">
                          <p class="mr-1 text-pink-100 text-sm font-sans">{{staticMeta.header_heading1}} {{staticMeta.header_heading2}}</p>
                        </div>
                        <div class="flex">
                          <div class="flex">
                            <p class="mr-1 text-pink-100 text-sm font-sans">
                              Press and Media
                            </p>
                          </div>
                        </div>
                        <div class="flex">
                          <div class="flex">
                            <p class="mr-1 text-pink-100 text-sm font-sans">BLOG</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="text-sm">
                      <p class="font-bold tracking-wide text-xl uppercase text-gray-50">
                        {{$t("ContactUs")}}
                      </p>
                      <div v-if="staticMeta.support.length" class="flex">
                        <p class="mr-1 text-pink-100 text-sm font-sans">
                          Support:<a target="_blank" class="px-2 text-white hover:text-pink-300"
                            :href="staticMeta.support">{{$t('LiveChat')}}</a>
                        </p>
                      </div>
                      <div class="flex">
                        <p class="mr-1 text-pink-100 text-sm font-sans">
                          Mail :<a target="_blank" :href="`mailto:${staticMeta.mail}`" aria-label="Our email" title="Our email" class="transition-colors px-5 duration-300 text-pink-50 hover:text-pink-300">{{staticMeta.mail}}</a>
                        </p>
                      </div>
                      <div v-if="staticMeta.address.length" class="flex">
                        <p class="mr-1 text-pink-100 text-sm font-sans">
                          {{$t("Address")}}:<a href="https://www.google.com/maps" target="_blank" rel="noopener noreferrer"
                            aria-label="Our address" title="Our address"
                            class="transition-colors duration-300 px-2 text-pink-50 hover:text-pink-300">
                            {{staticMeta.address}}
                          </a>
                        </p>
                      </div>
                    </div>
                    <div>
                      <span class="text-xl font-sans font-bold mt-3 tracking-wide uppercase text-gray-50">{{$t("SocialMedia")}}</span>
                      <div class="flex items-center mt-1 space-x-3">
                        <a traget="_blank" href="https://twitter.com/"
                          class="text-gray-50 transition-colors duration-300 hover:text-pink-300">
                          <svg viewBox="0 0 24 24" fill="currentColor" class="h-5 hover:text-pink-300">
                            <path
                              d="M24,4.6c-0.9,0.4-1.8,0.7-2.8,0.8c1-0.6,1.8-1.6,2.2-2.7c-1,0.6-2,1-3.1,1.2c-0.9-1-2.2-1.6-3.6-1.6 c-2.7,0-4.9,2.2-4.9,4.9c0,0.4,0,0.8,0.1,1.1C7.7,8.1,4.1,6.1,1.7,3.1C1.2,3.9,1,4.7,1,5.6c0,1.7,0.9,3.2,2.2,4.1 C2.4,9.7,1.6,9.5,1,9.1c0,0,0,0,0,0.1c0,2.4,1.7,4.4,3.9,4.8c-0.4,0.1-0.8,0.2-1.3,0.2c-0.3,0-0.6,0-0.9-0.1c0.6,2,2.4,3.4,4.6,3.4 c-1.7,1.3-3.8,2.1-6.1,2.1c-0.4,0-0.8,0-1.2-0.1c2.2,1.4,4.8,2.2,7.5,2.2c9.1,0,14-7.5,14-14c0-0.2,0-0.4,0-0.6 C22.5,6.4,23.3,5.5,24,4.6z">
                            </path>
                          </svg>
                        </a>
                        <a traget="_blank" href="https://www.instagram.com/"
                          class="text-gray-50 transition-colors duration-300 hover:text-pink-400">
                          <svg viewBox="0 0 30 30" fill="currentColor" class="h-6 hover:text-pink-300">
                            <circle cx="15" cy="15" r="4" class=""></circle>
                            <path
                              d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z">
                            </path>
                          </svg>
                        </a>
                        <a traget="_blank" href="https://www.facebook.com/"
                          class="text-gray-50 transition-colors duration-300 hover:text-pink-300">
                          <svg viewBox="0 0 24 24" fill="currentColor" class="h-5 hover:text-pink-300">
                            <path
                              d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z">
                            </path>
                          </svg>
                        </a>
                        <a traget="_blank" href="https://www.youtube.com/"
                          class="text-gray-50 transition-colors duration-300 hover:text-pink-300">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            class="bi bi-youtube h-6 hover:text-pink-300" viewBox="0 0 16 16">
                            <path
                              d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col-reverse justify-between pt-5 pb-10 border-t lg:flex-row">
                    <p class="text-sm text-gray-50">
                      {{$t("copyright")}}
                      <a target="_blank" class="text-white hover:text-pink-300"
                        href="https://dialogmakers-international.com/">{{staticMeta.header_heading1}} {{staticMeta.header_heading2}}.</a>
                    </p>
                    <ul class="flex flex-col mb-3 space-y-2 lg:mb-0 sm:space-y-0 sm:space-x-5 sm:flex-row">
                      <!-- <li>
                            <a
                              href="/"
                              class="text-sm text-gray-50"
                              >F.A.Q</a
                            >
                          </li> -->
                      <li>
                        <a target="_blank" href="/imprint" class="text-sm hover:text-pink-300 text-gray-50">{{$t("imprint")}}</a>
                      </li>
                      <li>
                        <a target="_blank" href="/privacy" class="text-sm hover:text-pink-300 text-gray-50">{{$t("privacy")}}
                          Policy</a>
                      </li>
                      <li>
                        <a target="_blank" href="/terms" class="text-sm hover:text-pink-300 text-gray-50">{{$t("terms")}} &amp;
                          Conditions</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div><ins class="adsbygoogle" style="display:block" data-ad-client="ca-pub-2781511921274321"
                data-ad-slot="4412224045" data-ad-format="auto" data-full-width-responsive="true"></ins>
              <!--New footer-->
            </section>
            <Adsense data-ad-client="ca-pub-2781511921274321" data-ad-slot="4412224045">
            </Adsense>
            <Adsense data-ad-client="ca-pub-2781511921274321" data-ad-slot="4412224045">
            </Adsense>
          </div>
        </main>
      </div>
    </div>




    <template>
      <div>
        <div class="relative h-screen justify-center  lg:hidden xl:hidden align-middle bg-red-600">
          <div class="relative h-screen ">
            <div class="absolute inset-0 object-cover w-full h-screen z-10 lg:hidden xl:hidden" >
              <imageslider class="" />
              <!-- <appimageslider/> -->
              <!-- <mobimageslider style="height: 1200px;" /> -->
              <div class="absolute inset-0   h-screen  bg-opacity-0 z-0 flex justify-center items-center">
                <!-- Content goes here -->
                <div class="relative   bg-gradient-to-t from-gray-900 bg-opacity-0 h-screen  justify-center align-middle">
                  <div class="px-4 py-10 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                    <div class="flex flex-col items-center justify-between xl:flex-row">
                      <!-- <div>    <img :src="getCommunityMeta.logo" class="h-16" alt=""></div> -->
                      <div>
                        <p class="font-sans italic text-white font-bold text-xl"></p>
                      </div>
                    </div>
                  </div>
                  <!-- join section starts here --> <div class="flex justify-center mx-auto ">    <img :src="getCommunityMeta.logo" class="h-24 mt-10" alt=""></div>
                  <div
                    class="px-4 mt-24 mb-5 mx-auto sm:max-w-xl md:max-w-screen justify-center align-middle lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                    <div class="max-w-xl sm:mx-auto lg:max-w-2xl">
                      <div class="flex flex-col mb-16 sm:text-center sm:mb-0">
                       
                        <div class="max-w-xl mb-5 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                          <p
                            class="max-w-lg mb-6  text-4xl font-bold leading-none tracking-tight text-gray-50 font-sans italic sm:text-4xl md:mx-auto">

                            {{staticMeta.header_heading1}} {{staticMeta.header_heading2}}
                          </p>

                        </div>
                        <div>
                          <router-link to="/login"><button href="/"
                              class="inline-flex items-center btn-sm bg-red-600 rounded-lg glass font-sans px-16 btn border-0 justify-center h-10 w-56  font-medium tracking-wide text-white transition duration-200 shadow-lg hover:bg-white hover:text-pink-500 bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none">
                              {{$t('Join')}}
                            </button></router-link> <br>
                          <p class="text-white font-sans font-bold -mt-4">or</p>
                          <router-link to="register"> <button
                              class="inline-flex py-2 items-center btn-sm bg-white rounded-lg font-sans px-3 btn border-0 justify-center h-10 w-56  font-bold tracking-wide text-red-500 transition duration-200 shadow-lg hover:bg-gray-500 hover:text-gray-50 bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"> {{$t('Createanaccount')}}</button></router-link>
                        </div>
                      </div>

                    </div>

                    <div>

                      <div class="flex align-middle justify-center mb-5  items-center mt-1 space-x-3 mx-auto ">
                        <a href="/"
                          class="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400">
                          <svg viewBox="0 0 24 24" fill="#ffffff" class="h-5">
                            <path
                              d="M24,4.6c-0.9,0.4-1.8,0.7-2.8,0.8c1-0.6,1.8-1.6,2.2-2.7c-1,0.6-2,1-3.1,1.2c-0.9-1-2.2-1.6-3.6-1.6 c-2.7,0-4.9,2.2-4.9,4.9c0,0.4,0,0.8,0.1,1.1C7.7,8.1,4.1,6.1,1.7,3.1C1.2,3.9,1,4.7,1,5.6c0,1.7,0.9,3.2,2.2,4.1 C2.4,9.7,1.6,9.5,1,9.1c0,0,0,0,0,0.1c0,2.4,1.7,4.4,3.9,4.8c-0.4,0.1-0.8,0.2-1.3,0.2c-0.3,0-0.6,0-0.9-0.1c0.6,2,2.4,3.4,4.6,3.4 c-1.7,1.3-3.8,2.1-6.1,2.1c-0.4,0-0.8,0-1.2-0.1c2.2,1.4,4.8,2.2,7.5,2.2c9.1,0,14-7.5,14-14c0-0.2,0-0.4,0-0.6 C22.5,6.4,23.3,5.5,24,4.6z">
                            </path>
                          </svg>
                        </a>
                        <a href="/"
                          class="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400">
                          <svg viewBox="0 0 30 30" fill="#ffffff" class="h-6">
                            <circle cx="15" cy="15" r="4"></circle>
                            <path
                              d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z">
                            </path>
                          </svg>
                        </a>
                        <a href="/"
                          class="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400">
                          <svg viewBox="0 0 24 24" fill="#ffffff" class="h-5">
                            <path
                              d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z">
                            </path>
                          </svg>
                        </a>
                        <a traget="_blank" href="https://www.youtube.com/channel/UC9xQrnNCDf75e2yaXsbVSWA/"><svg
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="w-7 h-7  "
                            style="color: #ffffff">
                            <path fill="currentColor"
                              d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z" />
                          </svg></a>

                      </div>




                    </div>

                  </div>

                  <div>
                    <p class="bg-red-50 py-5 text-red-500 font-sans font-bold  ">OVER 1M+ SINGLES WORLDWIDE</p>
                  </div>
                  <!-- <div class="bg-red-600 relative " style="object-fit: cover ; width: 100%; height: 290px;">
                    <div
                      class="px-4 py-8 mx-auto bg-opacity-0   sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-10 lg:px-5 lg:py-5">
                      <img src="@/assets/easterimages/5.jpg" alt=""
                        class="absolute inset-0 z-0 justify-center object-cover w-full h-full">
                      <p
                        class="text-xl  my-section transform translate-x-4 lg:text-6xl md:text-4xl sm:text-3xl font-sans text-pink-50">
                        It All Start With the date

                      </p>
                      <div class="max-w-xl sm:mx-auto lg:max-w-2xl">
                        <div class="flex mb-8 sm:text-center sm:mb-0">
                          <div class="max-w-xl mb-1 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                            <h2
                              class="max-w-lg mb-1 mt-1 w-full font-sans text-4xl italic leading-none tracking-tight text-white sm:text-4xl">
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <div class="bg-red-400">
                    <div
                      class="px-4 pt-16 mx-auto text-left sm:max-w-xl md:max-w-full lg:max-w-screen-xl bg-red-600 md:px-24 lg:px-8">
                      <div class="grid gap-10 row-gap-6 mb-8 sm:grid-cols-3 lg:grid-cols-4">
                        <div class="sm:col-span-2">
                          <p class="font-bold font-sans text-xl tracking-wide uppercase text-gray-50">
                            {{$t("About")}}
                          </p>
                          <div class="text-sm grid ">

                            <div class="flex">
                              <p class="mr-1 text-pink-100 font-sans text-sm">Global Chat</p>
                            </div>
                            <div class="flex">
                              <p class="mr-1 text-pink-100 text-sm font-sans">Global Chat</p>
                            </div>
                            <div class="flex">
                              <div class="flex">
                                <p class="mr-1 text-pink-100 text-sm font-sans">
                                  Press And Media
                                </p>
                              </div>
                            </div>
                            <!-- <div class="flex">
                        <div class="flex">
                          <p class="mr-1 text-pink-100 text-sm font-sans">Blogs</p>
                        </div>
                      </div> -->
                          </div>
                        </div>
                        <div class="text-sm">
                          <p class="font-bold tracking-wide text-xl uppercase text-gray-50">
                            {{$t("ContactUs")}}
                          </p>
                          <div class="flex">
                            <p class="mr-1 text-pink-100 text-sm font-sans">
                              {{$t('LiveChat')}} :<a class="px-2 text-white hover:text-pink-300"
                                href="https://tawk.to/chat/60d8ca3565b7290ac6382e01/1f97dbp6v">{{$t("ChatNow")}}</a>
                            </p>
                          </div>
                          <div class="flex">
                            <p class="mr-1 text-pink-100 text-sm font-sans">
                              Email :<a href="mailto:support@globalchat.us
  " aria-label="Our email" title="Our email"
                                class="transition-colors px-7 duration-300 text-pink-50 hover:text-pink-300">support@GlobalChat.com
                              </a>
                            </p>
                          </div>
                          <div class="flex">
                            <p class="mr-1 text-pink-100 text-sm font-sans">
                              {{$t("Address")}}:<a href="https://www.google.com/maps" target="_blank" rel="noopener noreferrer"
                                aria-label="Our address" title="Our address"
                                class="transition-colors duration-300 px-2 text-pink-50 hover:text-pink-300">
                                GWIK LLC, 5203 Juan Tabo Blvd.
4NE Suite 2b, Albuquerque, NM 87111 USA
                              </a>
                            </p>
                          </div>
                        </div>
                        <div>
                          <span class="text-xl font-sans font-bold mt-3 tracking-wide uppercase text-gray-50">{{$t("SocialMedia")}}</span>
                          <div class="flex items-center mt-1 space-x-3">
                            <a traget="_blank" href="https://twitter.com/flirttool/"
                              class="text-gray-50 transition-colors duration-300 hover:text-pink-300">
                              <svg viewBox="0 0 24 24" fill="currentColor" class="h-5 hover:text-pink-300">
                                <path
                                  d="M24,4.6c-0.9,0.4-1.8,0.7-2.8,0.8c1-0.6,1.8-1.6,2.2-2.7c-1,0.6-2,1-3.1,1.2c-0.9-1-2.2-1.6-3.6-1.6 c-2.7,0-4.9,2.2-4.9,4.9c0,0.4,0,0.8,0.1,1.1C7.7,8.1,4.1,6.1,1.7,3.1C1.2,3.9,1,4.7,1,5.6c0,1.7,0.9,3.2,2.2,4.1 C2.4,9.7,1.6,9.5,1,9.1c0,0,0,0,0,0.1c0,2.4,1.7,4.4,3.9,4.8c-0.4,0.1-0.8,0.2-1.3,0.2c-0.3,0-0.6,0-0.9-0.1c0.6,2,2.4,3.4,4.6,3.4 c-1.7,1.3-3.8,2.1-6.1,2.1c-0.4,0-0.8,0-1.2-0.1c2.2,1.4,4.8,2.2,7.5,2.2c9.1,0,14-7.5,14-14c0-0.2,0-0.4,0-0.6 C22.5,6.4,23.3,5.5,24,4.6z">
                                </path>
                              </svg>
                            </a>
                            <a traget="_blank" href="https://www.instagram.com/flirttool/"
                              class="text-gray-50 transition-colors duration-300 hover:text-pink-400">
                              <svg viewBox="0 0 30 30" fill="currentColor" class="h-6 hover:text-pink-300">
                                <circle cx="15" cy="15" r="4" class=""></circle>
                                <path
                                  d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z">
                                </path>
                              </svg>
                            </a>
                            <a traget="_blank" href="https://www.facebook.com/flirttool/"
                              class="text-gray-50 transition-colors duration-300 hover:text-pink-300">
                              <svg viewBox="0 0 24 24" fill="currentColor" class="h-5 hover:text-pink-300">
                                <path
                                  d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z">
                                </path>
                              </svg>
                            </a>
                            <a traget="_blank" href="https://www.youtube.com/channel/UC9xQrnNCDf75e2yaXsbVSWA"
                              class="text-gray-50 transition-colors duration-300 hover:text-pink-300">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                class="bi bi-youtube h-6 hover:text-pink-300" viewBox="0 0 16 16">
                                <path
                                  d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                              </svg>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="flex flex-col-reverse justify-between pt-5 pb-10 border-t lg:flex-row">
                        <p class="text-sm text-gray-50">
                          © Copyright 2024 Global Chat. All rights reserved. Powered by
                          <a target="_blank" class="text-white hover:text-pink-300"
                            href="">Global Chat.</a>
                        </p>
                        <ul class="flex flex-col mb-3 space-y-2 lg:mb-0 sm:space-y-0 sm:space-x-5 sm:flex-row">
                          <!-- <li>
                        <a
                          href="/"
                          class="text-sm text-gray-50"
                          >F.A.Q</a
                        >
                      </li> -->
                          <li>
                            <a target="_blank" href="/imprint"
                              class="text-sm hover:text-pink-300 text-gray-50">{{$t("Imprint")}}</a>
                          </li>
                          <li>
                            <a target="_blank" href="/privacy" class="text-sm hover:text-pink-300 text-gray-50">{{$t("privacy")}}</a>
                          </li>
                          <li>
                            <a target="_blank" href="/terms" class="text-sm hover:text-pink-300 text-gray-50">{{$t("terms")}}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <!-- social end -->
                </div>
              </div>
            </div>

          </div>

          <!-- <img src="@/assets/appbg/1.jpg" class="absolute inset-0 object-cover w-full h-screen" alt="" /> -->

          <!-- join section ends here -->


        </div>
      </div>
    </template>
  </div>
</template>
<script type="text/babel">
import { RAND_USERS } from "@/queries/auth";
import { register } from "@/utils/basicAuth";
import popup from "@/components/popup.vue";
import Adsense from 'vue-google-adsense/dist/Adsense.min.js'

import imageslider from "@/components/purple/imageslider.vue";
import { getCommunityMeta } from "@/utils/helpers.js";
import {getCommunityStaticMeta } from '@/communityMeta'

import {
  GET_BLOGS,
  GET_THEADS,
  PROMO_PIC,
  THEAD_PROMO,
  ADD_COL,
  VIDEO,
  BG_SLIDERS,
} from "../../queries/cms";

export default {
  name: "demo",
  computed: {
    staticMeta:function() { 
      let host = window.location.host
      let meta = getCommunityStaticMeta(host)
      return meta
    }
  },
  components: {
    popup,
    Adsense,
    imageslider,
  },
  apollo: {
    additionalCollection: {
      query: ADD_COL,
      client: "cms",
      context: {
        headers: {
          Authorization: "Bearer 0QFKriEFCKVwegEZMidnRX8ZGqNJLnK6c0kXTQRzf-A",
        },
      },
    },
    backgroundSliderCollection: {
      query: BG_SLIDERS,
      client: "cms",
      fetchPolicy: "network-only",
      context: {
        headers: {
          Authorization: "Bearer 0QFKriEFCKVwegEZMidnRX8ZGqNJLnK6c0kXTQRzf-A",
        },
      },
    },
    topInternalPromoCollection: {
      query: THEAD_PROMO,
      client: "cms",
      context: {
        headers: {
          Authorization: "Bearer 0QFKriEFCKVwegEZMidnRX8ZGqNJLnK6c0kXTQRzf-A",
        },
      },
    },
    topHeaderCollection: {
      query: GET_THEADS,
      client: "cms",
      context: {
        headers: {
          Authorization: "Bearer 0QFKriEFCKVwegEZMidnRX8ZGqNJLnK6c0kXTQRzf-A",
        },
      },
    },
    blogCollection: {
      query: GET_BLOGS,
      client: "cms",
      context: {
        headers: {
          Authorization: "Bearer 0QFKriEFCKVwegEZMidnRX8ZGqNJLnK6c0kXTQRzf-A",
        },
      },
    },
    promoCollection: {
      query: PROMO_PIC,
      client: "cms",
      context: {
        headers: {
          Authorization: "Bearer 0QFKriEFCKVwegEZMidnRX8ZGqNJLnK6c0kXTQRzf-A",
        },
      },
    },
    promoVideoCollection: {
      query: VIDEO,
      client: "cms",
      context: {
        headers: {
          Authorization: "Bearer 0QFKriEFCKVwegEZMidnRX8ZGqNJLnK6c0kXTQRzf-A",
        },
      },
    },
    randUserSet: {
      query: RAND_USERS,
      result({
        data: {
          randUserSet: { counts },
        },
      }) {
        this.usersOnlie = counts[0];
        this.girlsOnlie = counts[1];
        this.menOnlie = counts[2];
        this.coupleOnlie = counts[3];
        this.transOnlie = counts[4];
        this.gaysOnlie = counts[5];
        this.lesOnlie = counts[6];
      },
    },
  },
  data() {
    return {
      getCommunityStaticMeta,
      getCommunityMeta,
      openPop: false,
      loadingReg: false,
      promoImages: [
        {
          url: require("../../assets/prom1.png"),
        },
        {
          url: require("../../assets/prom2.png"),
        },
        {
          url: require("../../assets/pride.png"),
        },
      ],
      topInternalPromoCollection: {},
      promoVideoCollection: {},
      topHeaderCollection: {},
      backgroundSliderCollection: {},
      promoCollection: {},
      blogCollection: {},
      randUserSet: null,
      delay: 1000,
      // endVal: 120500,
      usersOnlie: 0,
      girlsOnlie: 0,
      menOnlie: 0,
      coupleOnlie: 0,
      transOnlie: 0,
      gaysOnlie: 0,
      lesOnlie: 0,
      options: {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: "",
      },
      email: "",
      username: "",
      firstName: "",
      lastName: "",
      password: "",
    };
  },
  created() {
    this.$apollo.queries.randUserSet.refetch();
    this.$apollo.queries.backgroundSliderCollection.refetch();
    const fragment = window.location.hash;
    if (fragment != "") {
      setTimeout(() => {
        document.getElementById(fragment.slice(1)).scrollIntoView(true);
      }, 1);
    }
    if (this.$store.state.core.flirtok) {
      this.$router.push("/find-matches");
    }
  },
  watch: {},
  methods: {
      changeLang($event) {
      this.$store.commit("setCurrentLang", $event.target.value)
      this.$i18n.locale = $event.target.value
    },
    doSignUp() {
      this.loadingReg = true;
      register(
        this.username,
        this.email,
        this.firstName,
        this.lastName,
        this.password,
        "",
        this.$store.state.core.addr,
        ({ data, fail }) => {
          this.loadingReg = false;
          if (!fail) {
            const { user, token, credit } = data.register;
            this.$store.commit("setUserData", user);
            // this.$store.commit("setBalance", credit.balance);
            this.$store.commit("setBalance", credit.balance + credit.flirtons);
            this.$store.commit("setRandState", Math.random(100));
            this.$store.commit("setFlirtTok", token);
            this.$router.push("/find-matches");
          } else {
            console.error("Error creating a new user", Object.keys(data));
          }
        }
      );
    },
    formSubmitted(e) {
      e.preventDefault();
      console.log("submitted");
      this.doSignUp();
    },
    playBtn() {
      this.openPop = true;
    },
    dateNow() {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const d = new Date();
      // document.write("The current month is " + monthNames[d.getMonth()]);
      return `${d.getDate()}, ${monthNames[d.getMonth()]} ${d.getFullYear()}`;
      // return new Date().toLocaleDateString()
    },
    toLogin() {
      if (this.$store.state.core.flirtok) {
        window.location.assign("/find-matches");
      } else {
        this.$router.push("/login");
      }
    },
    // onReady: function (instance, CountUp, ICountUp2) {
    // const self = this;
    // // instance.update(self.endVal + 100);
    // instance.update(self.usersOnlie + 100);
    // instance.update(self.girlsOnlie + 100);
    // instance.update(self.menOnlie + 100);
    // instance.update(self.coupleOnlie + 100);
    // instance.update(self.gaysOnlie + 100);
    // instance.update(self.lesOnlie + 100);
    // }
  },


  metaInfo() {
    return {
      title: `${staticMeta.header_heading1} ${staticMeta.header_heading2}  | Online Dating | The finest global  dating website around. `,
      meta: [
        {
          name: "description",
          content:
            ` ${staticMeta.header_heading1} ${staticMeta.header_heading2} is the dating community of the future.GlobalChat   will soon define online dating. With  AI System, GlobalChat  dating gives everyone a chance to get their dream date`,
        },
        {
          property: "og:title",
          content:
            `${staticMeta.header_heading1} ${staticMeta.header_heading2}  | Online Dating | The finest global  dating website around.`,
        },
        { property: "og:site_name", content: `${staticMeta.header_heading1} ${staticMeta.header_heading2}   Online Dating` },
        { property: "og:type", content: `${staticMeta.header_heading1} ${staticMeta.header_heading2}   Online Dating` },
        { name: "googlebot", content: "index,follow" },
      ],
    };

  },


};

</script>

<style scoped>
.iCountUp {
  font-size: 20px;
  margin: 0;
  color: #252525;
}

#video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}

iframe {
  position: absolute;
  top: 0;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-width: 1000px;
  max-height: 563px;
}

.video-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
  max-width: 1000px;
  max-height: 563px;
}

.container {
  position: relative;
  text-align: center;
  color: white;
}

/* Top left text */
.top-left {
  position: absolute;
  top: 8px;
  left: 16px;
}
</style>
<style>
.slick-slide {
  height: 500px;
}

.slick-slide img {
  height: 1024px;
}</style>
