import { render, staticRenderFns } from "./UserProfilePublic.vue?vue&type=template&id=dfd15a92&scoped=true&"
import script from "./UserProfilePublic.vue?vue&type=script&lang=js&"
export * from "./UserProfilePublic.vue?vue&type=script&lang=js&"
import style0 from "./UserProfilePublic.vue?vue&type=style&index=0&id=dfd15a92&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dfd15a92",
  null
  
)

export default component.exports