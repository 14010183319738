export const appliedThemes = [
  'Flirttool',
  'Erotic',
  'Purple',
  'Yellow',
  'Red',
]
// change this value to activate a theme
// export const activeTheme = 'Yellow';
export const activeTheme = 'Purple';
// export const activeTheme = 'Flirttool';~
