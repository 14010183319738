<template>
  <div class="flex justify-center">
    <public-nav-bar></public-nav-bar>
    <div
      class="col-span- bordered sm:mx-5 lg:mx-0 globalcompress lg:-mt-1 sm:-mt-1 lg:pl-5 xl:pl-24"
    >
      <popup :showing="blockD" @close="blockD = false">
        <h2 class="py-10 text-xl font-bold text-gray-900">
          Are you sure you would like to block
          <span>@{{ userProfile.user.username }}</span>
        </h2>
        <div class="flex flex-row justify-center gap-10">
          <button class="mt-3 bg-pink-100 btn" @click="block">Yes</button>

          <button class="mt-3 bg-pink-100 btn" @click="blockD = false">No</button>
        </div>
      </popup>
      <popup :showing="showing" @close="showing = false">
        <h2 class="text-xl font-bold text-gray-900">Enter you reason for this report</h2>
        <textarea
          v-model="reportReason"
          class="h-32 mt-5 border-pink-300 input focus:border-pink-600"
        ></textarea>
        <button class="mt-3 bg-pink-100 btn" @click="submitReport">Submit Report</button>
      </popup>
      <notification-bar />
      <div
        class="grid grid-cols-1 px-3 px-10 lg:grid-cols-11 md:mx-5 lg:mt-16 py-5 mb-54"
        style=""
      >
        <div class="col-span-3 bordered sm:mx-5 lg:mx-0">
          <div v-if="loadingProfile">
            <div class="h-56 mx-auto mt-5 bg-gray-500 rounded-md animate-pulse"></div>
            <div class="space-y-2">
              <div class="h-4 mt-3 bg-gray-500 rounded animate-pulse"></div>
            </div>
          </div>

          <div class="mt-4 rounded-none shadow-md card dark:bg-gray-800" v-else>
            <div class="flex flex-col h-full bg-white shadow-xl dark:bg-gray-700">
              <div class="flex items-center justify-end ml-3 h-7"></div>
              <div class="pb-3 shadow-lg bg-pink-50 dark:bg-gray-600 rounded-b-3xl">
                <div
                  class="flex flex-col items-center py-3 space-y-5 bg-white rounded-b-3xl dark:bg-gray-700"
                >
                  <img
                    class="object-cover border-2 border-pink-300 rounded-full cursor-pointer hover:border-pink-500 w-36 h-36 profileImg scale-125 hover:scale-75 ease-in duration-500"
                    @click="profileClicked"
                    :src="getURL(userProfile.user.profilePic)"
                  />

                  <!-- <div class="card-actions">
                   
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="text-pink-500 h-7 w-7"
                      viewBox="0 0 20 20"
                      fill="red"
                    >
                      <path
                        d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z"
                      />
                    </svg>
                    <h2
                      class="text-left text-gray-500 dark:text-gray-200 card-title text-md"
                    >
                      <span class="font-sans" v-text="userProfile.likes"></span>
                      <span class="font-sans"> Likes</span>
                    </h2>
                   
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="ml-5 h-7 w-7 dark:text-gray-200"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                      <path
                        fill-rule="evenodd"
                        d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <h2
                      class="text-left text-gray-500 dark:text-gray-200 card-title text-md"
                    >
                      <span
                        class="font-sans"
                        v-text="userProfile.visits"
                      ></span>
                      <span class="font-sans"> Visits</span>
                    </h2>
                  </div> -->
                </div>
                <div
                  class="grid items-center justify-around grid-cols-3 gap-4 py-2 divide-x px-7 divide-solid"
                >
                  <div class="flex flex-col items-center col-span-1">
                    <router-link class="" :to="getChatRoute()">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-8 h-8 font-sans text-pink-400 dark:text-pink-50"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                        /></svg
                      ><span
                        class="font-sans text-sm text-pink-500 text-semi-bold card-title dark:text-pink-50"
                        >Message</span
                      >
                    </router-link>
                  </div>
                  <div class="flex flex-col items-center col-span-1 px-3">
                    <button class="mt-2" @click="sendPoke">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-8 h-8 font-sans text-pink-400 dark:text-pink-50"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M7 11.5V14m0-2.5v-6a1.5 1.5 0 113 0m-3 6a1.5 1.5 0 00-3 0v2a7.5 7.5 0 0015 0v-5a1.5 1.5 0 00-3 0m-6-3V11m0-5.5v-1a1.5 1.5 0 013 0v1m0 0V11m0-5.5a1.5 1.5 0 013 0v3m0 0V11"
                        /></svg
                      ><span
                        class="font-sans text-sm text-pink-500 text-semi-bold card-title dark:text-pink-50"
                        >Poke</span
                      >
                    </button>
                  </div>
                  <div class="flex flex-col items-center col-span-1 px-3">
                    <button class="mt-2" @click="blockD = true">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-8 h-8 font-sans text-pink-200 dark:text-pink-50"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                        /></svg
                      ><span
                        @click="blockD = true"
                        class="font-sans text-sm text-pink-200 text-semi-bold dark:text-pink-50 card-title"
                        >Block</span
                      >
                    </button>
                  </div>
                </div>
              </div>

              <div class="grid grid-cols-1 gap-1">
                <button
                  class="w-full mt-2 text-gray-800 bg-gray-200 border-0 btn dark:bg-pink-100"
                  @click="reportUser"
                >
                  <span>report</span>
                </button>
              </div>
              <a href="#">
                <hp
                  class="px-5 font-sans text-2xl text-center text-pink-500 uppercase font-semi-bold dark:text-white card-title"
                >
                  About
                </hp>
              </a>
              <div class="px-2 mt-3 text-left uppercase">
                <ul v-if="userProfile.user.looks">
                  <li class="font-sans font-bold dark:text-white">Personal</li>
                  <li
                    class="mt-4 font-sans text-sm dark:text-white"
                    v-if="userProfile.user.looks.ethnicity"
                  >
                    Ethnicity- {{ userProfile.user.looks.ethnicity }}
                  </li>
                  <li
                    class="mt-3 font-sans text-sm dark:text-white"
                    v-if="userProfile.user.looks.body_type"
                  >
                    Body Type: {{ userProfile.user.looks.body_type }}
                  </li>
                  <li
                    class="mt-3 font-sans text-sm dark:text-white"
                    v-if="userProfile.user.looks.gender"
                  >
                    Gender: {{ userProfile.user.looks.gender }}
                  </li>
                  <li
                    class="mt-3 font-sans text-sm dark:text-white"
                    v-if="userProfile.user.looks.height"
                  >
                    Height: {{ userProfile.user.looks.height }}
                  </li>
                  <li
                    class="mt-3 font-sans text-sm dark:text-white"
                    v-if="userProfile.user.user_basic.relationship"
                  >
                    Relationship: {{ userProfile.user.user_basic.relationship }}
                  </li>
                  <li
                    class="mt-3 font-sans text-sm dark:text-white"
                    v-if="userProfile.user.user_basic.workstatus"
                  >
                    Work: {{ userProfile.user.user_basic.workstatus }}
                  </li>
                  <li
                    class="mt-3 font-sans text-sm dark:text-white"
                    v-if="userProfile.user.user_basic.education_level"
                  >
                    Education: {{ userProfile.user.user_basic.education_level }}
                  </li>
                  <CoolLightBox :items="items" :index="index" @close="index = null">
                  </CoolLightBox>
                </ul>
                <hr class="py-2 border-gray-50" />
              </div>
              <div
                class="px-2 mt-3 text-left uppercase"
                v-if="userProfile.user.lifeStyle"
              >
                <ul class="mt-3">
                  <li
                    class="font-sans font-bold dark:text-white"
                    v-if="userProfile.user.lifeStyle"
                  >
                    LIFE STYLE
                  </li>
                  <li
                    class="mt-4 font-sans text-sm dark:text-white"
                    v-if="userProfile.user.lifeStyle.live_with"
                  >
                    Lives With- {{ userProfile.user.lifeStyle.live_with }}
                  </li>
                  <li
                    class="mt-3 font-sans text-sm dark:text-white"
                    v-if="userProfile.user.lifeStyle.car"
                  >
                    Car: {{ userProfile.user.lifeStyle.car }}
                  </li>
                  <li
                    class="mt-3 font-sans text-sm dark:text-white"
                    v-if="userProfile.user.lifeStyle.religion"
                  >
                    Religion: {{ userProfile.user.lifeStyle.religion }}
                  </li>
                  <li
                    class="mt-3 font-sans text-sm dark:text-white"
                    v-if="userProfile.user.lifeStyle.smoke"
                  >
                    Smoke: {{ userProfile.user.lifeStyle.smoke }}
                  </li>
                  <li
                    class="mt-3 font-sans text-sm dark:text-white"
                    v-if="userProfile.user.lifeStyle.drinking"
                  >
                    Drink: {{ userProfile.user.lifeStyle.drinking }}
                  </li>
                  <li
                    class="mt-3 font-sans text-sm dark:text-white"
                    v-if="userProfile.user.lifeStyle.travel"
                  >
                    Travel: {{ userProfile.user.lifeStyle.travel }}
                  </li>
                  <li
                    class="mt-3 font-sans text-sm dark:text-white"
                    v-if="userProfile.user.user_basic.education_level"
                  >
                    Education: {{ userProfile.user.user_basic.education_level }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- send section start -->
        <div class="col-span-7 sm:mx-5 lg:mx-0 md:mx-5">
          <div class="col-span-3">
            <div
              class="relative h-full mt-5 overflow-hidden rounded-none shadow-md card lg:mx-3 dark:bg-gray-800"
            >
              <div
                class="grid grid-cols-1 mt-5 rounded-none shadow-none lg:grid-cols-2 sm:grid-cols-2 card lg:mx-3 dark:bg-gray-800"
              >
                <div>
                  <p
                    class="px-5 font-sans text-sm text-left text-pink-500 uppercase font-semi-bold text-md dark:text-white card-title"
                  >
                    <a href="#">
                      <span
                        class="font-sans text-4xl font-bold text-pink-500"
                        v-if="userProfile.user.base_profile"
                      >
                        {{ userProfile.user.base_profile.firstName }}
                        {{ userProfile.user.base_profile.lastName }}</span
                      >
                    </a>
                  </p>
                </div>
                <div class="grid grid-cols-1">
                  <div class="card-actions">
                    <!-- likes count block -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-8 h-8 text-pink-500"
                      viewBox="0 0 20 20"
                      fill="pink"
                    >
                      <path
                        d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z"
                      />
                    </svg>
                    <h2
                      class="text-left text-gray-500 dark:text-gray-200 card-title text-md"
                    >
                      <span class="font-sans" v-text="userProfile.likes"></span>
                      <span class="font-sans"> Likes</span>
                    </h2>

                    <!-- views count block -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-8 h-8 ml-3 dark:text-gray-200"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                      <path
                        fill-rule="evenodd"
                        d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <h2
                      class="text-left text-gray-500 dark:text-gray-200 card-title text-md"
                    >
                      <span class="font-sans" v-text="userProfile.visits"></span>
                      <span class="font-sans"> Visits</span>
                    </h2>
                  </div>
                </div>
              </div>
              <div
                class="grid grid-cols-1 mt-5 rounded-none border-2 card lg:mx-3 dark:bg-gray-800"
              >
                <div class="px-5 card-actions">
                  <p class="px-0 font-bold text-left mb-1 font-sans text-pink-500">
                    Bio :
                  </p>
                  <br />
                  <p
                    style="font-size: 17px"
                    class="pt-5 mb-5 font-sans font-normal break-all text-left shadow-none dark:text-gray-200"
                    v-if="userProfile.user.user_basic"
                  >
                    {{ userProfile.user.user_basic.bio }}
                  </p>
                  <!-- likes count block -->
                  <!-- <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="text-pink-500 h-7 w-7"
                      viewBox="0 0 20 20"
                      fill="red"
                    >
                      <path
                        d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z"
                      />
                    </svg> -->
                  <!-- <p
                    class="text-2xl text-center text-gray-500 dark:text-gray-200 card-title"
                  >
                    <span class="font-sans" v-text="userProfile.likes"></span><br>
                    <span class="font-sans text-blue-700"> Likes</span>
                  </p> -->
                  <!-- views count block -->
                  <!-- <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="ml-5 h-7 w-7 dark:text-gray-200"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                      <path
                        fill-rule="evenodd"
                        d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                        clip-rule="evenodd"
                      />
                    </svg> -->
                  <!-- <p
                    class="px-10 text-2xl text-center text-gray-500 dark:text-gray-200 card-title"
                  >
                    <span class="font-sans" v-text="userProfile.visits"></span><br>
                    <span class="font-sans text-blue-700"> Visits</span>
                  </p> -->
                </div>
                <div></div>
              </div>
              <!-- <div
                class="grid hidden grid-cols-2 mt-5 font-sans italic text-left rounded-none shadow-sm lg:block dark:bg-gray-700 card lg:mx-3 dark:bg-gray-800"
              ></div> -->
              <section class="text-gray-600 body-font -mt-16">
                <div class="container px-5 py-24 mx-auto">
                  <p class="px-2 font-bold font-sans text-left mb-5 text-gray-500">
                    Popular {{staticMeta.header_heading1}} {{staticMeta.header_heading2}} Users
                  </p>
                  <div class="flex flex-wrap -m-4 dark:bg-gray-700">
                    <div class="p-4 lg:w-1/4 md:w-1/2">
                      <div class="h-full flex flex-col items-center text-center">
                        <a
                          target="_blank"
                          :href="`https://${staticMeta.domain}/@GabbyBaby316`"
                          class="rounded-full"
                        >
                          <img
                            alt="team"
                            class="object-center object-cover rounded-full h-24 w-24 border-pink-500 hover:border-gray-50 border-4 scale-125 hover:scale-75 ease-in duration-500"
                            src="https://funnyflirts.net/media/upload/f_pics/52587307_1.jpg"
                        /></a>
                      </div>
                    </div>
                    <div class="p-4 lg:w-1/4 md:w-1/2">
                      <div class="h-full flex flex-col items-center text-center">
                        <a
                          target="_blank"
                          
                          :href="`https://${staticMeta.domain}/@SabrinaH24`"
                          class="rounded-full"
                        >
                          <img
                            alt="team"
                            class="object-center object-cover rounded-full h-24 w-24 border-pink-500 hover:border-gray-50 border-4 scale-125 hover:scale-75 ease-in duration-500"
                            src="https://funnyflirts.net/media/upload/f_pics/52810947_1.jpg"
                        /></a>
                      </div>
                    </div>
                    <div class="p-4 lg:w-1/4 md:w-1/2">
                      <div class="h-full flex flex-col items-center text-center">
                        <a target="_blank" 
                          :href="`https://${staticMeta.domain}/@DungonDad`"
                      
                          ><img
                            alt="team"
                            class="object-center object-cover rounded-full h-24 w-24 border-pink-500 hover:border-gray-50 border-4 scale-125 hover:scale-75 ease-in duration-500"
                            src="https://funnyflirts.net/media/upload/trans_pics/9830777_0.jpg"
                        /></a>
                      </div>
                    </div>
                    <div class="p-4 lg:w-1/4 md:w-1/2">
                      <div class="h-full flex flex-col items-center text-center">
                        <a target="_blank" 
                         :href="`https://${staticMeta.domain}/@jessikacay99`"
                          >
                          <img
                            alt="team"
                            class="object-center object-cover rounded-full h-24 w-24 border-pink-500 hover:border-gray-50 border-4 scale-125 hover:scale-75 ease-in duration-500"
                            src="https://funnyflirts.net/media/upload/f_pics/52779689_1.jpg"
                        /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <!-- <div
                class="grid w-full grid-cols-1 gap-6 px-1 py-3 mt-5 mb-5 overflow-x-auto rounded-none shadow-lg shadow-cyan-500/50 card lg:mx-1 dark:bg-gray-700"
              >
                <div class="flex w-full gap-16 px-2 py-3 -space-x-2 overflow-auto">
                  <a
                    target="_blank"
                    href="https://funnyflirts.net/@GabbyBaby316"
                    class="rounded-full"
                  >
                    <img
                      class="inline-block border-2 border-pink-500 rounded-full ring-2 ring-white"
                      src="https://funnyflirts.net/media/upload/f_pics/52587307_1.jpg"
                      alt=""
                  /></a>
                  <a
                    target="_blank"
                    href="https://funnyflirts.net/@SabrinaH24"
                    class="rounded-full"
                    ><img
                      class="inline-block border-2 border-pink-500 rounded-full ring-2 ring-white"
                      src="https://funnyflirts.net/media/upload/f_pics/52810947_1.jpg"
                      alt=""
                  /></a>
                  <a target="_blank" href="https://funnyflirts.net/@DungonDad"
                    ><img
                      class="inline-block border-2 border-pink-500 rounded-full ring-2 ring-white"
                      src="https://funnyflirts.net/media/upload/trans_pics/9830777_0.jpg"
                      alt=""
                  /></a>
                  <a target="_blank" href="https://funnyflirts.net/@jessikacay99">
                    <img
                      class="inline-block border-2 border-pink-500 rounded-full cover ring-2 ring-white"
                      src="https://funnyflirts.net/media/upload/f_pics/52779689_1.jpg"
                      alt=""
                  /></a>
                </div>
              </div> -->

              <div class="-mt-20 overflow-auto bg-white dark:bg-gray-800">
                <div
                  class="max-w-2xl px-4 py-16 -mt-16 mx-auto sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8"
                >
                  <div class="divide-y-2 divide-pink-500 -mt-5 divide-opacity-25">
                    <div class="text-center py-2"></div>
                    <div class="py-2 font-sans uppercase text-left dark:text-white">
                      Photos
                    </div>
                  </div>

                  <div
                    class="grid grid-cols-1 mt-6 sm:grid-cols-2 lg:grid-cols-1 xl:gap-x-8"
                  >
                    <div class="relative group">
                      <div
                        class="overflow-hidden bg-white rounded-md min-h-80 aspect-w- aspect-h-1 dark:bg-gray-700 lg:aspect-none lg:h-full"
                        style=""
                        v-if="userProfile.user.photos.length > 0"
                      >
                        <img
                          class="w-20 h-12 mt-1 border-2 rounded-none shadow-xl cursor-pointer elem hover:border-pink-500 scale-125 hover:scale-75 ease-in duration-500"
                          v-for="(pic, idx) in userProfile.user.photos"
                          :key="idx"
                          @click="slideClicked(idx)"
                          :src="getURL(pic.image)"
                          :style="pic.unsafe ? 'filter: blur(14px);' : ''"
                          style="width: 150px; height: 150px"
                        />
                      </div>
                    </div>
                    <div
                      class="absolute inset-x-0 bottom-0 w-full h-full font-sans text-3xl font-bold leading-4 text-center text-white uppercase"
                      v-if="false"
                    >
                      <div class="relative">
                        <img
                          class="absolute"
                          style="filter: brightness(0.5)"
                          src="@/assets/sexy-couple.jpg"
                        />
                        <div class="relative">
                          <p class="pt-20">Unlock Exclusive Pictures</p>
                          <router-link
                            class="mt-5 text-xl btn hover:bg-pink-500 hover:text-white"
                            to="/flirts"
                            >Unlock Now</router-link
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div v-if="userProfile.user.photos.length > 0" class="wrapper">
                <figure>
                  <img
                    class="w-20 h-12 mt-2 rounded-none shadow-xl cursor-pointer elem"
                    v-for="(pic, idx) in userProfile.user.photos"
                    :key="idx"
                    @click="slideClicked(idx)"
                    :src="getURL(pic.image)"
                    :style="pic.unsafe ? 'filter: blur(14px);' : ''"
                  />
                </figure>
              </div> -->
              <!-- <div
                class="absolute inset-x-0 bottom-0 w-full h-full font-sans text-3xl font-bold leading-4 text-center text-white uppercase"
                v-if="false"
              >
                <div class="relative">
                  <img
                    class="absolute"
                    style="filter: brightness(0.5)"
                    src="@/assets/sexy-couple.jpg"
                  />
                  <div class="relative">
                    <p class="pt-20">Unlock Exclusive Pictures</p>
                    <router-link
                      class="mt-5 text-xl btn hover:bg-pink-500 hover:text-white"
                      to="/flirts"
                      >Unlock Now</router-link
                    >
                  </div>
                </div>
              </div> -->
            </div>
            <div
              class="hidden mt-5 rounded-none shadow-md dark:text-white card lg:mx-3 dark:bg-gray-800"
            >
              <h1
                class="px-5 font-sans text-sm font-bold text-center text-pink-500 uppercase dark:text-white font-semi-bold text-md card-title"
              >
                Looks And Personality
              </h1>
              <div class="card-actions lg:flex-row">
                <div
                  v-if="userProfile.user.looks"
                  class="grid grid-cols-3 gap-10 mb-12 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4"
                >
                  <div v-if="userProfile.user.looks.ethnicity">
                    <p
                      class="px-5 font-sans font-semibold text-center text-2xs dark:text-white"
                    >
                      Ethnicity
                    </p>
                    <p
                      class="mx-5 text-left text-gray-500 uppercase text-2xs dark:text-gray-200 card-title text-md"
                    >
                      {{ userProfile.user.looks.ethnicity }}
                    </p>
                  </div>

                  <div v-if="userProfile.user.looks.body_type">
                    <p
                      class="px-5 font-sans font-semibold text-center text-2xs dark:text-white"
                    >
                      Body Type
                    </p>
                    <p class="px-5 pt-2 text-sm text-center dark:text-gray-300">
                      {{ userProfile.user.looks.body_type }}
                    </p>
                  </div>

                  <div v-if="userProfile.user.looks.gender">
                    <p
                      class="px-5 font-sans font-semibold text-center text-2xs dark:text-white"
                    >
                      Gender
                    </p>
                    <p class="px-5 pt-2 text-sm text-center dark:text-gray-300">
                      {{ userProfile.user.looks.gender }}
                    </p>
                  </div>
                  <div v-if="userProfile.user.looks.height">
                    <p
                      class="px-5 font-sans font-bold text-left uppercase text-2xs dark:text-white"
                    >
                      Height
                    </p>
                    <p
                      class="mx-5 text-left text-gray-500 uppercase text-2xs dark:text-gray-200 card-title text-md"
                    >
                      {{ userProfile.user.looks.height }}
                    </p>
                  </div>
                </div>

                <CoolLightBox
                  :items="items"
                  :index="index"
                  @close="index = null"
                  @click="customEvent"
                  type="button"
                >
                  <template> <button name="custom-button">button</button></template>
                </CoolLightBox>
              </div>
            </div>
            <!-- basic info card start -->
            <div
              class="hidden mt-5 rounded-none shadow-md card lg:mx-3 dark:bg-gray-800"
              v-if="userProfile.user.user_basic"
            >
              <h1
                class="px-5 font-sans text-sm text-center text-pink-500 uppercase font-semi-bold text-md dark:text-white card-title"
              >
                Basic Information
              </h1>
              <div class="card-actions lg:flex-row">
                <div
                  class="grid grid-cols-3 gap-10 mb-12 text-center sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4"
                >
                  <div v-if="userProfile.user.user_basic.relationship">
                    <p
                      class="px-5 font-sans font-bold text-left uppercase text-2xs dark:text-white"
                    >
                      Relationship
                    </p>
                    <p
                      class="mx-5 text-left text-gray-500 uppercase text-2xs dark:text-gray-200 card-title text-md"
                    >
                      {{ userProfile.user.user_basic.relationship }}
                    </p>
                  </div>

                  <div v-if="userProfile.user.user_basic.workstatus">
                    <p
                      class="px-5 font-sans font-bold text-left uppercase text-2xs dark:text-white"
                    >
                      Work
                    </p>
                    <p
                      class="mx-5 text-left text-gray-500 uppercase text-2xs dark:text-gray-200 card-title text-md"
                    >
                      {{ userProfile.user.user_basic.workstatus }}
                    </p>
                  </div>

                  <div v-if="userProfile.user.user_basic.education_level">
                    <p
                      class="px-5 font-sans font-bold text-left uppercase text-2xs dark:text-white"
                    >
                      Education
                    </p>
                    <p
                      class="mx-5 text-left text-gray-500 uppercase text-2xs dark:text-gray-200 card-title text-md"
                    >
                      {{ userProfile.user.user_basic.education_level }}
                    </p>
                  </div>
                </div>

                <CoolLightBox :items="items" :index="index" @close="index = null">
                </CoolLightBox>
              </div>
            </div>
            <!-- basic info card end -->
            <div
              class="hidden mt-5 rounded-none shadow-md card lg:mx-3 dark:bg-gray-800"
              v-if="userProfile.user.lifeStyle"
            >
              <h1
                class="px-5 font-sans text-sm text-center text-pink-500 uppercase font-semi-bold text-md dark:text-white card-title"
              >
                Life Style
              </h1>

              <div class="card-actions lg:flex-row">
                <div
                  class="grid grid-cols-3 gap-10 mb-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4"
                >
                  <div v-if="userProfile.user.lifeStyle.live_with">
                    <p
                      class="px-5 font-sans font-bold text-left uppercase text-2xs dark:text-white"
                    >
                      Lives With
                    </p>
                    <p
                      class="mx-5 text-left text-gray-500 uppercase text-2xs dark:text-gray-200 card-title text-md"
                    >
                      {{ userProfile.user.lifeStyle.live_with }}
                    </p>
                  </div>

                  <div v-if="userProfile.user.lifeStyle.car">
                    <p
                      class="px-5 font-sans font-bold text-left uppercase text-2xs dark:text-white"
                    >
                      Car
                    </p>
                    <p
                      class="mx-5 text-left text-gray-500 uppercase text-2xs dark:text-gray-200 card-title text-md"
                    >
                      {{ userProfile.user.lifeStyle.car }}
                    </p>
                  </div>

                  <div v-if="userProfile.user.lifeStyle.religion">
                    <p
                      class="px-5 font-sans font-bold text-left uppercase text-2xs dark:text-white"
                    >
                      Religion
                    </p>
                    <p
                      class="mx-5 text-left text-gray-500 uppercase text-2xs dark:text-gray-200 card-title text-md"
                    >
                      {{ userProfile.user.lifeStyle.religion }}
                    </p>
                  </div>

                  <div v-if="userProfile.user.lifeStyle.smoke">
                    <p
                      class="px-5 font-sans font-bold text-left uppercase text-2xs dark:text-white"
                    >
                      Smoke
                    </p>
                    <p
                      class="mx-5 text-left text-gray-500 uppercase text-2xs dark:text-gray-200 card-title text-md"
                    >
                      {{ userProfile.user.lifeStyle.smoke }}
                    </p>
                  </div>
                  <div v-if="userProfile.user.lifeStyle.drinking">
                    <p
                      class="px-5 font-sans font-bold text-left uppercase text-2xs dark:text-white"
                    >
                      Drink
                    </p>
                    <p
                      class="mx-5 text-left text-gray-500 uppercase text-2xs dark:text-gray-200 card-title text-md"
                    >
                      {{ userProfile.user.lifeStyle.drinking }}
                    </p>
                  </div>

                  <div v-if="userProfile.user.lifeStyle.travel">
                    <p
                      class="px-5 font-sans font-bold text-left uppercase text-2xs dark:text-white"
                    >
                      Travel
                    </p>
                    <p
                      class="mx-5 text-left text-gray-500 uppercase text-2xs dark:text-gray-200 card-title text-md"
                    >
                      {{ userProfile.user.lifeStyle.travel }}
                    </p>
                  </div>
                </div>

                <CoolLightBox :items="items" :index="index" @close="index = null">
                </CoolLightBox>
              </div>
            </div>

            <!-- basic info card start -->

            <!-- basic info card end -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getURL } from "../utils/helpers";
import CoolLightBox from "vue-cool-lightbox";
import popup from "../components/popup.vue";
import { BLOCK_USER, REPORT_USER, SEND_POKE, PUBLIC_PROFILE } from "../queries/auth";
import PublicNavBar from "../components/PublicNavBar.vue";
import { getCommunityStaticMeta } from "@/communityMeta";

export default {
  components: { popup, CoolLightBox, PublicNavBar },
  data: () => ({
    items: [],
    index: null,
    blockD: false,
    showing: false,
    allUserPics: [],
    reportReason: "",
    userProfile: null,
    loadingProfile: true,
    // routeParam: this.$route.params.username
  }),
  apollo: {
    userProfile: {
      query: PUBLIC_PROFILE,
      variables() {
        return {
          username: this.$route.params.username,
        };
      },
      update(data) {
        this.loadingProfile = false;
        console.clear();
        data.publicProfile.user.photos.forEach((pic) => {
          this.allUserPics.push(getURL(pic.image));
        });
        return data.publicProfile;
      },
    },
  },
  created() {
    this.$apollo.queries.userProfile.refetch();
    // this.fetchUserProfile()
    // const allPics = this.$store.state.core.user.photos;
    // allPics.forEach(e => {
    // 	this.allUserPics.push(getURL(e.image))
    // })
  },
  computed: {
    staticMeta: function () {
      let host = window.location.host;
      let meta = getCommunityStaticMeta(host);
      return meta;
    },
  },
  methods: {
    getURL,
    sendPoke() {
      this.$apollo
        .mutate({
          mutation: SEND_POKE,
          variables: {
            input: this.userProfile.user.username,
          },
        })
        .then(
          ({
            data: {
              sendPoke: { error },
            },
          }) => {
            this.$notify(
              {
                group: "top",
                title: error
                  ? "You have finished your pokes for today :("
                  : `Poke Sent to @${this.userProfile.user.username}`,
                text: error ? "Whoops" : "Success",
                type: "info", // info, warning
              },
              5000
            );
          }
        );
    },
    getChatRoute() {
      switch (this.$mq) {
        case "sm":
          return `/chat-list?dm=${this.userProfile.user.id}`;
        case "md":
          return `/chat-list?dm=${this.userProfile.user.id}`;
        case "lg":
          return `/chat?dm=${this.userProfile.user.id}`;
        default:
          break;
      }
      // ($mq == 'sm' || 'md')
      // 	? `/chat-list?dm=${userProfile.user.id}`
      // 	: `/chat?dm=${userProfile.user.id}`
    },
    slideClicked(idx) {
      console.log(idx, "IDX");
      this.index = idx;
      this.items = this.allUserPics.filter((obj) => {
        return obj.unsafe !== true;
      });

      // this.items = [
      // 	"https://picsum.photos/id/1005/400/250",
      // 	"https://picsum.photos/id/1005/400/250",
      // 	"https://picsum.photos/id/1005/400/250",
      // 	"https://picsum.photos/id/1005/400/250",
      // ];
    },
    profileClicked() {
      this.items = [getURL(this.userProfile.user.profilePic)];
      // console.log(this.userProfile)
      this.index = 0;
    },
    submitReport() {
      this.$notify(
        {
          group: "top",
          title: "You report is submitted thank you !!",
          type: "info", // info, warning
        },
        5000
      );
      this.showing = false;
      this.$apollo.mutate({
        mutation: REPORT_USER,
        variables: {
          input: {
            target: JSON.stringify(this.userProfile.user.id),
            reason: this.reportReason,
          },
        },
      });
    },
    block() {
      this.blockD = false;
      this.$apollo
        .mutate({
          mutation: BLOCK_USER,
          variables: {
            input: JSON.stringify(this.userProfile.user.id),
          },
        })
        .then(() => {
          this.$notify(
            {
              group: "top",
              title: "User blocked successfully!!",
              type: "info", // info, warning
            },
            5000
          );
          this.$router.push("/find-matches");
        });
    },
    reportUser() {
      this.showing = true;
    },
  },
  metaInfo() {
    return {
      title: " Are you lonely ? Let us connect on flirttool |Free Registration | Join The finest global dating website around | FlirtTool",
      meta: [
        {
          name: "description",
          content:
            " FlirtTool offers you new levels of online dating. FlirtTool’s ultimate creation of AI personality MAZU will help users be paired with someone that is 94% their perfect match",
        },
        {
          property: "og:title",
          content:
            " Are you lonely ? Let us connect on flirttool |Free Registration | Join The finest global dating website around | FlirtTool",
        },
        { property: "og:site_name", content: "FlirtTool Online Dating" },
        { property: "og:type", content: "FlirtTool Online Dating" },
        { name: "googlebot", content: "index,follow" },
      ],
    };
  },
};
</script>
<style scoped>
.wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  height: 250px;
  white-space: nowrap;
}

.elem {
  display: inline-block;
  object-fit: cover;
  width: 40%;
  height: 200px;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: rgba(128, 128, 128, 0.418);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(128, 128, 128, 0.418);
}
</style>
