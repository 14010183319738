<template>
  <div class="px-1 cursor-pointer">
    <div
      class="mt-2 text-center shadow-lg h-42 w-72 gap rounded-xl card bg-pink-50 dark:bg-gray-800"
      @click="$router.push('/@' + user.username)" style="width: 100%"
    >
      <figure>
        <img
          :src="
            user.profilePic ? getURL(user.profilePic) : require('@/assets/pholder.webp')
          "
          style="object-fit: cover; width: 100%; height: 256px" class=""
        />
        <div
          class="absolute top-0 right-0 h-16 w-16 px-2 mt-1 text-right avatar online rounded-full"
        >
          <svg
            class="h-3 w-3 shadow-lg text-green-300 bg-green-300 rounded-full shadow-green-500 animate-pulse text-right"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" />
            <circle cx="12" cy="12" r="9" />
          </svg>
        </div>
        <!-- src="https://picsum.photos/id/1005/400/250"  -->
      </figure>  <div class="absolute bottom-1 left-1 flex gap-1 text-white text-xs items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                        <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <span> <h2
          class="font-sans text-xs mt-5 text-left card-title text-gray-50 dark:text-gray-50"
          v-if="user.base_profile"
        >
          {{ user.base_profile.firstName }} {{ user.base_profile.lastName }}
          <p class="mb-5 -mt-4 text-2xs dark:text-gray-300">
            {{ user.userLocation }}
          </p>
        </h2>
        <h2 class="card-title" v-else></h2></span>
                </div>
      <!-- <div class="absolute bottom-0 left-0 right-0 px-4 py-1 bg-gray-800 bg-opacity-10 shadow-sm">
        <h2
          class="font-sans text-xs mt-5 text-left card-title text-gray-50 dark:text-gray-50"
          v-if="user.base_profile"
        >
          {{ user.base_profile.firstName }} {{ user.base_profile.lastName }}
          <p class="mb-5 -mt-4 text-2xs dark:text-gray-300">
            {{ user.userLocation }}
          </p>
        </h2>
        <h2 class="card-title" v-else></h2>
      </div> -->
      <!-- <div class="font-sans card-body">
        <h2
          class="text-lg card-title dark:text-gray-300"
          v-if="user.base_profile"
        >
          {{ user.base_profile.firstName }} {{ user.base_profile.lastName }}
        </h2>
        <h2 class="card-title" v-else></h2>

        <div v-if="geocode">
          <p class="text-sm dark:text-gray-300">
            {{ user.userLocation }}
          </p>
        </div>
      </div> -->
    </div>
    <!-- start -->
    <div class="rand_bottom_bar" v-if="page == 'find_matches'">
      <button
        class="btn waves-effect like scale-125 hover:scale-75 ease-in duration-500"
        data-userid="22195"
        data-ajax-post="/useractions/like"
        data-ajax-params="userid=22195&amp;username=u_a9eed3c96"
        data-ajax-callback="callback_like"
        @click="likeProfile"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-5 h-5 text-pink-500"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
      <button
        class="btn waves-effect dislike _dislike_text22195 scale-125 hover:scale-75 ease-in duration-500"
        data-userid="22195"
        id="dislike_btn"
        data-ajax-post="/useractions/dislike"
        data-ajax-params="userid=22195"
        data-ajax-callback="callback_dislike"
        @click="unlike()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-5 h-5 text-blue-500"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </div>
    <div class="rand_bottom_bar" v-else-if="page == 'likes' || page == 'matches'">
      <button
        class="btn waves-effect like"
        id="like_btn"
        @click="page == 'likes' ? unlike() : unmatch()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-5 h-5 text-blue-500"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </div>

    <div class="rand_bottom_bar" v-else-if="page == 'disliked'">
      <button class="btn waves-effect like" id="like_btn" @click="removeDisliked()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-5 h-5 text-red-500"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </div>
    <!-- end -->
  </div>
</template>

<script>
import { DISLIKE_PROFILE, LIKE_PROFILE } from "../queries/matches";
import { getURL } from "../utils/helpers";
export default {
  data: () => ({}),
  props: {
    user: Object,
    page: String,
    geocode: Boolean,
  },
  methods: {
    getURL,
    unlike() {
      this.$emit("popIndex");
      this.$apollo.mutate({
        mutation: DISLIKE_PROFILE,
        variables: {
          user: this.user.username,
        },
      });
    },
    unmatch() {
      this.$emit("popIndex");
    },
    likeProfile() {
      this.$emit("popIndex");
      this.$apollo
        .mutate({
          mutation: LIKE_PROFILE,
          variables: {
            user: this.user.username,
          },
        })
        .then(() => {
          console.log("requset completed");
        })
        .catch((e) => {
          // catch exeption
          console.error(e);
        });
    },
    disLikeProfile() {
      this.$emit("popIndex");
    },
    removeDisliked() {
      this.$emit("popIndex");
    },
  },
};
</script>
