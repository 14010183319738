<template>
	<div class="text-left text-gray-800" v-on-clickaway="closeMenu">
		<div class="
				right-0
				shadow-none
				text-sm
				overflow-hidden
				border-none
				z-20
			">
			<div class="bg-yellow-5100 dark:bg-gray-700 shadow-xs" role="menu" aria-orientation="vertical"
				aria-labelledby="options-menu">
				<center v-if="loadingNotification">
					<div class="mt-14 lds-ellipsis">
						<div></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
				</center>
				<div v-else>
					<ul class=" overflow-y-visible " style="height: 510px" v-if="notifications.length > 0">
						<li v-for="(notice, idx) in notifications" :key="idx" @click="toLink(notice)" class="
								flex
								justify-between
								items-center
								bg-red-100
								dark:bg-gray-500
								mt-1 border-4
								p-2
                             shadow-sm
								hover:shadow-xl
								rounded-xl border-red300
								cursor-pointer
								transition border-red-100 hover:scale-125  duration-500
							">
							<div class="flex ml-2" style=" height: 36px;">
								<div class="flex-none">
									<div class="avatar h-8 w-8">
										<img :src="getURL(notice.ref_user.profilePic)" width="40" height="40"
											class="rounded-full object-cover border-4" />
									</div>
								</div>
								<!-- {{ notice.message }} -->
								<div class="flex flex-col ml-2 mt-0">
									<span class="   font-sans text-sm  text-red-900 mt-1  dark:text-white"
										:class="{ 'font-sans': !notice.read }">
										{{ notice.message.slice(0, 30) }}
										{{ notice.message.length > 30 ? '...' : '' }}
									</span>
								</div>

							</div>
							<div class="flex flex-col items-center">
								<span class="text-gray-300"></span>
								<i class="fa fa-star text-green-400"></i>
							</div>
						</li>
					</ul>
					<ul v-else class="overflow-y-scroll max-h-96">
						<li>
							<div class=" text-center font-sans text-lg my-10 dark:text-gray-200">
								<div class="load-wrapp">
									<div class="search-container flex justify-center mx-auto">
										<div class="search-icon">🔍</div>
										<!-- <div class="search-text font-sans text-sm uppercase">{{$t("SearchingFornotificaiton")}}</div> -->
									</div>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>


	</div>
</template>
<script>
import { mixin as clickaway } from "vue-clickaway";
import { getURL } from "@/utils/helpers";
import { logout } from "@/utils/basicAuth";
import { NOTIFICATIONS } from '@/queries/matches';
export default {
	mixins: [clickaway],
	data: () => ({
		notifications: [],
		label: "First label",
		loadingNotification: false,
	}),
	apollo: {
		notifications: {
			query: NOTIFICATIONS,
			result({ data: { notifications } }) {
				this.notifications = notifications;
				this.loadingNotification = false
				setTimeout(() => {
					// Empty notificaiton count
					this.$store.commit("changeNotificationCount", 0)
				}, 3000);
			}
		}
	},
	methods: {
		
		logout,
		getURL,
		toLink(notice) {
			if (notice.sparoute) {
				const finalLink = notice.link == "/chat" ? `/chat?dm=${notice.ref_user.id}` : notice.link
				this.$router.push(finalLink)
			} else {
				window.location.assign(notice.link)
			}
			// this.$router.push(link)
		},
		closeMenu() {
			this.isMenuOpen = false;
		},
	},
	computed: {
		notificationObject() {
			return this.$store.getters.notificationObject
		}
	},
	watch: {
		notificationObject(value) {
			const freshNots = [value].concat(this.notifications)
			this.notifications = freshNots;
		}
	}
};
</script>

<style>
.search-container {
	display: flex;
	align-items: center;
}

.search-icon {
	font-size: 32px;
	color: #3498db;
	animation: bounce 2s infinite;
}

.search-text {
	font-size: 24px;
	margin-left: 10px;
	color: #333;
	animation: fadeIn 2s infinite;
}

@keyframes bounce {

	0%,
	20%,
	50%,
	80%,
	100% {
		transform: translateY(0);
	}

	40% {
		transform: translateY(-10px);
	}

	60% {
		transform: translateY(-5px);
	}
}

@keyframes fadeIn {

	0%,
	100% {
		opacity: 0;
	}

	50% {
		opacity: 1;
	}
}
</style>